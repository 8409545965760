import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import eventApi from '@api/eventApi'
import useStepHistory from '@hooks/useStepHistory'
import Button from '@ui/atoms/Button'
import Typography from '@ui/atoms/Typography'
import Section from '@ui/wrappers/Section'
import { useTranslatedStep } from '@hooks/useTranslated'

export default function TemplateError() {
  const { t } = useTranslation()
  const { current } = useStepHistory()
  const { title, desc } = useTranslatedStep(current?.step)

  if (!current?.step) return null

  return (
    <>
      {/* <Headline /> */}
      <Section
        className={twMerge(
          'flex h-[calc(var(--max-height-template)_-_1rem)] w-full flex-col items-center justify-center p-8'
        )}
      >
        <div className="flex w-full flex-col items-center gap-8 rounded-[2rem] border border-red-500/25 bg-gradient-to-br from-red-500/0 to-red-500/10 p-16">
          <div className="flex w-full flex-col gap-6">
            <Typography
              variant="H4"
              className={twMerge(
                'line-clamp-1 text-center leading-tight tracking-tight text-red-700',
                'font-bold' // MIXED
              )}
            >
              {title || <>&nbsp;</>}
            </Typography>
            <Typography
              variant="MD"
              className="line-clamp-2 whitespace-pre-line text-center text-red-700/50"
            >
              {desc}
            </Typography>
          </div>
          <Button
            text={t('restart')}
            variant="outlined"
            onClick={eventApi.goBack}
          />
        </div>
      </Section>
    </>
  )
}
