import { useMemo } from 'react'

import useConfig from '@api/useConfig'

import useParams from '@hooks/useParams'

import { StepConfig, StepOption, StepOptionValue } from '@type/config'

import { getFlatOptionsValues } from '@utils/optionUtils'
import shouldDisplayOption from '@utils/shouldDisplayOption'
import matchVideo, { filterVideos } from '@utils/matchVideo'
import { getValidatedOptionParams } from '@utils/validateCondition'
import differenceInYears from 'date-fns/differenceInYears'
import { Order, Refraction } from '@type/initialParams'

const getAge = (birthday: Date) => differenceInYears(new Date(), birthday)
const getAdjustedVbox = ({ vbox, y_ext = 0 }: Order) =>
  vbox ? vbox + y_ext - 10 : undefined

const getPrice = (
  {
    price,
    pricePLN,
  }: {
    price?: number
    pricePLN?: number
  },
  currency: string
) => {
  if (currency === 'PLN') {
    return pricePLN
  }

  return price
}

const getOptionPrice = (option: StepOption, currency: string) => {
  return {
    price: getPrice(option, currency),
    overrides: Object.fromEntries(
      Object.entries(option.overrides || {}).map(([key, value]) => [
        key,
        {
          ...value,
          price: getPrice(value, currency),
        },
      ])
    ),
  }
}

export const useAllOptionValues = ({
  values,
  order,
  ref,
}: {
  values: Record<string, StepOptionValue>
  order?: number
  ref?: Partial<Refraction>
}) => {
  const params = useParams()

  if (!params) {
    throw new Error('Initial params not set')
  }
  const { orders, birthday, ...initial } = params
  return useMemo(() => {
    const optionValues = getFlatOptionsValues(values)

    const orderValues = orders[order || 0]
    return {
      ...initial,
      ...orderValues,
      ...optionValues,
      ref: {
        ...initial.ref,
        ...ref,
      },
      // We don't want a date but age
      age: getAge(birthday),
      adjustedVbox: orderValues ? getAdjustedVbox(orderValues) : undefined,
    }
  }, [values, birthday, initial, orders, ref])
}

const useFilteredOptions = ({
  step,
  values,
  order,
}: {
  order?: number
  step?: StepConfig
  values: Record<string, StepOptionValue>
}) => {
  const all = useAllOptionValues({
    values,
    order,
  })
  const { data } = useConfig()
  const conditions = data?.conditions || {}
  if (!step) {
    return {
      options: [],
      videos: [],
    }
  }

  const currency = data?.currency || 'EUR'

  const options = step.options
    .filter(
      (option) => !!shouldDisplayOption({ option, values: all, conditions })
    )
    .map((option) => ({
      ...option,
      ...getOptionPrice(option, currency),
      ...getValidatedOptionParams(option, all, conditions),
    }))

  const videos = filterVideos(step.videos || [], all, conditions)

  const video = useMemo(
    () =>
      matchVideo(
        options.map((o) => (o.disabled ? `disabled:${o.value}` : o.value)),
        videos
      ),
    [options, videos]
  )

  return {
    options: options.map((option) =>
      video?.recommended === option.value
        ? { ...option, isRecommended: true }
        : option
    ),
    videos,
    video,
  }
}

export default useFilteredOptions
