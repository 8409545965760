import { ResolvedStepOption, StepConfig, VideoConfig } from '@type/config'
import { i18n } from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const keys = {
  common: {
    step: {
      title: ({ variable }: StepConfig) => `steps.${variable}.step.title`,
      desc: ({ variable }: StepConfig) => `steps.${variable}.step.desc`,
    },
    option: {
      title: ({ variable }: StepConfig, option: ResolvedStepOption) =>
        `steps.${variable}.items.${option.value.toLowerCase()}.title`,
      desc: ({ variable }: StepConfig, option: ResolvedStepOption) =>
        `steps.${variable}.items.${option.value.toLowerCase()}.desc`,
    },
    overrides: {
      label: (
        { variable }: StepConfig,
        option: ResolvedStepOption,
        param: string
      ) =>
        `steps.${variable}.overrides.${option.value.toLowerCase()}.${param}.label`,
    },
  },
  unique: {
    video: {
      url: (video: VideoConfig) => `unique.video.${video.name}.url`,
    },
    step: {
      title: ({ name }: StepConfig) => `unique.${name}.title`,
      desc: ({ name }: StepConfig) => `unique.${name}.desc`,
    },
    option: {
      title: (_: StepConfig, option: ResolvedStepOption) =>
        `unique.item.${option.tlKey}.title`,
      desc: (_: StepConfig, option: ResolvedStepOption) =>
        `unique.item.${option.tlKey}.desc`,
      pricePrefix: (_: StepConfig, option: ResolvedStepOption) =>
        `unique.item.${option.tlKey}.pricePrefix`,
      priceText: (_: StepConfig, option: ResolvedStepOption) =>
        `unique.item.${option.tlKey}.priceText`,
    },
    overrides: {
      label: (_: StepConfig, option: ResolvedStepOption, param: string) =>
        `unique.item.${option.tlKey}.overrides.${param}`,
    },
  },
}

const getTl = (
  uniqueKey: string | undefined,
  key: string | null,
  i18n: i18n
) => {
  if (uniqueKey && i18n.exists(uniqueKey)) {
    console.log('using unique key', uniqueKey)
    return i18n.t(uniqueKey)
  }
  if (key && i18n.exists(key)) {
    console.log('using common key', key)
    return i18n.t(key)
  }

  console.log('no key found', uniqueKey, key)

  return ''
}

export const useTranslatedStep = (step?: StepConfig | null) => {
  const { t, i18n } = useTranslation()

  return useMemo(() => {
    if (!step) {
      return {
        title: '',
        desc: '',
      }
    }
    const uTitleKey = keys.unique.step.title(step)
    const uDescKey = keys.unique.step.desc(step)
    const titleKey = keys.common.step.title(step)
    const descKey = keys.common.step.desc(step)
    return {
      title: getTl(uTitleKey, titleKey, i18n),
      desc: getTl(uDescKey, descKey, i18n),
    }
  }, [step?.title, step?.desc, t])
}

export const useTranslatedOption = (
  step: StepConfig,
  option: ResolvedStepOption
) => {
  const { t, i18n } = useTranslation()

  return useMemo(() => {
    const uTitleKey = keys.unique.option.title(step, option)
    const uDescKey = keys.unique.option.desc(step, option)

    const uPricePrefix =
      option.tlKey && keys.unique.option.pricePrefix(step, option)
    const uPriceText =
      option.tlKey && keys.unique.option.priceText(step, option)

    const titleKey = keys.common.option.title(step, option)
    const descKey = keys.common.option.desc(step, option)

    const overrides = Object.entries(option.overrides || {}).map(
      ([param, override]) => [
        param,
        {
          ...override,
          label: getTl(
            keys.unique.overrides.label(step, option, param),
            keys.common.overrides.label(step, option, param),
            i18n
          ),
        },
      ]
    )

    return {
      title: getTl(uTitleKey, titleKey, i18n),
      desc: getTl(uDescKey, descKey, i18n),
      pricePrefix: getTl(uPricePrefix, null, i18n) || undefined,
      priceText: getTl(uPriceText, null, i18n) || undefined,
      overrides: Object.fromEntries(overrides),
    }
  }, [step.title, step.desc, t])
}

export const useTranslatedVideo = (video?: VideoConfig | null) => {
  const { i18n } = useTranslation()

  return useMemo(() => {
    if (!video) {
      return video
    }
    const uKey = keys.unique.video.url(video)

    const url = getTl(uKey, null, i18n)

    if (!url) {
      return null
    }

    return {
      ...video,
      url,
    }
  }, [video])
}
