import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import useStepHistory from '@hooks/useStepHistory'
import Headline from '@ui/organisms/Headline'
import Section from '@ui/wrappers/Section'
import useOptionClick from '@hooks/useOptionClick'
import { ResolvedStepOption, StepConfig } from '@type/config'
import OptionSmall from '@ui/options/OptionSmall'
import { getPricingColor } from '@utils/paramActions'
import { useCurrencySign } from '@hooks/useCurrencyFormat'
import {
  AnimateHeadline,
  AnimateOptionEntry,
  AnimateOptionEntryDelay,
} from '../styles'
import { useTranslatedOption, useTranslatedStep } from '@hooks/useTranslated'

export default function TemplateSmall() {
  const { current, options } = useStepHistory()

  const { title, desc } = useTranslatedStep(current?.step)
  if (!current?.step) return null
  const { step } = current

  return (
    <>
      <Headline title={title} desc={desc} className={AnimateHeadline} />
      <Section
        className={twMerge(
          'flex flex-wrap items-center justify-center gap-8 px-8 pb-8'
        )}
      >
        {options.map((option, index) => (
          <OptionWrapper
            key={option.title + option.value}
            index={index}
            step={step}
            option={option}
          />
        ))}
      </Section>
      {/* TODO: Recommended Button? */}
    </>
  )
}

const OptionWrapper = ({
  step,
  option,
  index,
}: {
  step: StepConfig
  option: ResolvedStepOption
  index: number
}) => {
  const { t } = useTranslation()
  const { selectOption } = useStepHistory()
  const currencySign = useCurrencySign()

  const tl = useTranslatedOption(step, option)

  const onClick = useOptionClick({
    onClick: () =>
      selectOption({
        ...option,
        ...tl,
      }),
    locked: option.locked,
    title: option.title,
    confirm: step.confirm,
  })

  const color =
    (step.variable.includes('package') && getPricingColor(option.value)) ||
    undefined

  return (
    <OptionSmall
      title={tl.title}
      desc={tl.desc}
      image={option.images?.[0]}
      price={{
        value: option.price,
        sign: currencySign,
        prefix: tl.pricePrefix,
        text: tl.priceText,
        color,
      }}
      label={option.isRecommended ? t('recommended') : undefined}
      onClick={onClick}
      disabled={option.disabled}
      style={{
        animation: AnimateOptionEntry,
        animationDelay: AnimateOptionEntryDelay(index),
      }}
    />
  )
}
