import { useEffect, useRef } from 'react'

import useStepHistory from '@hooks/useStepHistory'
import { useTranslatedOption } from '@hooks/useTranslated'
import { ResolvedStepOption, StepConfig } from '@type/config'

const SelectOption = ({
  option,
  step,
}: {
  option: ResolvedStepOption
  step: StepConfig
}) => {
  const { selectOption } = useStepHistory()
  const tl = useTranslatedOption(step, option)
  const selected = useRef(false)
  useEffect(() => {
    // HACK: This is a hack to prevent the automatic template
    // from firing twice in StrictMode
    if (selected.current) return
    selected.current = true
    selectOption({
      ...option,
      ...tl,
    })
  }, [])

  return null
}

export default function TemplateAutomatic() {
  const { current, options } = useStepHistory()

  if (!options[0]) {
    throw new Error('Missing valid option for automatic template')
  }
  if (!current?.step) return null

  return <SelectOption option={options[0]} step={current.step} />
}
