import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import useStepHistory from '@hooks/useStepHistory'
import Info from '@ui/atoms/Info'
import Player from '@ui/organisms/Player'

import {
  sAbsolute,
  sContainer,
  sOptionContainerSimple,
  sOptionsBackground,
  sOptionsContainer,
} from './styles'
import { SkipButtonValue } from '@type/config'
import { useQuery } from '@tanstack/react-query'
import { ParsedSubtitles, endpoints } from '@cwa/types'
import axios from 'axios'

interface Props {
  children?: React.ReactNode
  videoName?: string
  videoUrl?: string
  elaiId?: string
  classNameContainer?: string
  classNameOptions?: string
  placeholderImage?: string
  overlayTime?: number
  overlayHeight?: number
  simple?: boolean
  skipButton?: SkipButtonValue
  stopSecond: number | undefined
  disableControls?: boolean
  forceAutoPlay?: boolean
  hideTrack?: boolean
  hideSubtitles?: boolean
  scale?: number
}

export default function VideoContainer({
  videoName,
  videoUrl,
  children,
  classNameContainer,
  classNameOptions,
  placeholderImage,
  overlayTime,
  overlayHeight,
  simple,
  stopSecond,
  elaiId,
  disableControls,
  forceAutoPlay,
  hideTrack,
  hideSubtitles,
  scale,
}: Props) {
  const { dev } = useStepHistory()
  const [isOverlay, setOverlay] = useState(true)
  const subtitles = useQuery<ParsedSubtitles>({
    queryKey: ['subtitles', elaiId],
    queryFn: async () => {
      const res = await axios.get(
        endpoints.videos.subtitles.get({
          id: elaiId || '',
        }),
        {
          baseURL: '/api',
        }
      )

      return res.data
    },
    enabled: !!elaiId,
  })

  if (!videoUrl) {
    return (
      <div className=" relative flex h-[720px] flex-col items-center pb-24">
        <div className="relative mt-10 flex w-1/2 flex-col gap-3">
          {children}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center">
      {/* {videoName && (
        <div className="relative inset-0 w-1/2">
          <Info className="left-4 top-3 -translate-x-0 bg-black/50 px-2 py-1 text-[0.75rem] font-normal leading-none text-white opacity-50">
            {videoName}
          </Info>
        </div>
      )} */}
      {/* {dev && (
        <div
          onClick={() => setOverlay((prev) => !prev)}
          className="absolute left-1/2 top-0 z-[100] flex w-1/2 -translate-x-1/2 cursor-pointer items-center justify-end p-3 px-4"
        >
          <span className="rounded-[2rem] bg-black/50 px-2 py-1 text-[0.75rem] font-bold uppercase leading-none text-white opacity-50 transition-opacity hover:opacity-100">
            {isOverlay ? 'ON' : 'OFF'}
          </span>
        </div>
      )} */}
      <div className={twMerge(sContainer, classNameContainer)}>
        {videoUrl && (
          <Player
            url={videoUrl}
            isOverlay={isOverlay}
            setOverlay={setOverlay}
            overlayTime={overlayTime}
            placeholderImage={placeholderImage}
            stopSecond={stopSecond}
            subtitles={subtitles.data}
            disableControls={disableControls}
            forceAutoPlay={forceAutoPlay}
            hideTrack={hideTrack}
            hideSubtitles={hideSubtitles}
            scale={scale}
          />
        )}
        {videoUrl && (
          <>
            {simple && (
              <div className={twMerge(sAbsolute, sOptionsBackground)} />
            )}
            <div
              className={twMerge(
                sAbsolute,
                sOptionsContainer(),
                classNameOptions,
                simple && sOptionContainerSimple,
                !isOverlay && 'pointer-events-none opacity-0',
                'animate-[fadein_0.5s_ease-out]'
              )}
              style={
                overlayHeight
                  ? {
                      height: `${overlayHeight}%`,
                      maxHeight: `${overlayHeight}%`,
                    }
                  : {}
              }
            >
              {children}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
