import { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player/vimeo'
import { twMerge } from 'tailwind-merge'

import Loading from './Loading/Loading'
import Controls from './Controls/Controls'
import Track from './Track/Track'
import { Progress, SeekType } from './types'
import { sAbsolute, sPlayer, sTransition } from './styles'
import { ParsedSubtitles } from '@cwa/types'
import Subtitles from './Subtitles/Subtitles'
// import Performance from './Performance/Performance'
import useStepHistory from '@hooks/useStepHistory'
import { useTranslation } from 'react-i18next'
import IconButtonCircle from '@ui/molecules/IconButtonCircle'

interface Props {
  url: string
  isOverlay: boolean
  setOverlay: (overlay: boolean) => void
  overlayTime?: number
  placeholderImage?: string
  disableDimmer?: boolean
  stopSecond?: number
  subtitles?: ParsedSubtitles
  disableControls?: boolean
  forceAutoPlay?: boolean
  hideTrack?: boolean
  hideSubtitles?: boolean
  scale?: number
}

const STOP_SECOND = 0.1 // seconds before the end of the video

const defaultProgress: Progress = {
  loaded: 0,
  loadedSeconds: 0,
  played: 0,
  playedSeconds: 0,
}

export default function Player({
  url,
  isOverlay,
  setOverlay,
  overlayTime,
  placeholderImage,
  disableDimmer = true,
  stopSecond = STOP_SECOND,
  subtitles,
  disableControls,
  forceAutoPlay,
  hideTrack,
  hideSubtitles,
  scale = 1,
}: Props) {
  const { t } = useTranslation()
  const { dev } = useStepHistory()
  const ref = useRef<ReactPlayer>(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [playing, setPlaying] = useState(true)
  const [progress, setProgress] = useState(defaultProgress)
  const [duration, setDuration] = useState(0)
  const [interactions, setInteractions] = useState(0)

  const lastSecond = duration - stopSecond
  const overlayTimeNumber = typeof overlayTime === 'number' ? overlayTime : -0.5

  const overlaySecond =
    overlayTimeNumber >= 0 ? overlayTimeNumber : lastSecond + overlayTimeNumber

  const isFinished = Boolean(
    lastSecond >= 0 && progress.playedSeconds >= lastSecond
  )

  useEffect(() => {
    if (!ref.current) return
    if (playing && progress.playedSeconds >= lastSecond) {
      ref.current.seekTo(lastSecond, 'seconds')
      setPlaying(false)
    }
    if (progress.loaded > 0 && progress.playedSeconds >= overlaySecond)
      setOverlay(true)

    /* HACK: forces video to stop despite setting playing state to false */
    if (!interactions && progress.loaded > 0.1) {
      setPlaying(false)
      setSeekTo(0, 'fraction')
    }
  }, [progress.played])

  useEffect(() => {
    if (forceAutoPlay) {
      setInteractions(1)
      setPlaying(true)
      setOverlay(false)
    }
  }, [])

  const setSeekTo = (value: number, type?: SeekType) => {
    if (!ref.current) return
    ref.current.seekTo(value, type || 'fraction')
  }

  const handleProgress = (state: Progress) => {
    setProgress({
      loaded: state.loaded || 0,
      loadedSeconds: state.loadedSeconds || 0,
      played: state.played || 0,
      playedSeconds: state.playedSeconds || 0,
    })
  }

  const handleDuration = (duration: number) => {
    setDuration(duration)
  }

  const onReady = () => {
    if (!error) setError(false)
  }

  const onError = (e: any) => {
    console.error(e)
    setError(true)
    setPlaying(false)
    setLoading(false)
    setOverlay(true)
  }

  const onControlPlay = (playing: boolean) => {
    setPlaying(playing)

    if (progress.loaded > 0 && progress.playedSeconds >= overlaySecond)
      setOverlay(true)
    else setOverlay(!playing)

    setInteractions((prev) => prev + 1)
  }

  const onControlRepeat = () => {
    setSeekTo(0, 'seconds')
    setPlaying(true)
    setOverlay(false)
  }

  return (
    <>
      {!forceAutoPlay && (
        <img
          className={twMerge(sAbsolute, sTransition, 'object-cover', 'z-[13]')}
          src="/images/video-skipped.jpg"
        />
      )}
      <div className={twMerge(sAbsolute, 'z-[14] overflow-hidden')}>
        {placeholderImage && (
          <img
            className={twMerge(sAbsolute, 'scale-[0.997] object-cover')}
            src={placeholderImage}
          />
        )}
        <div
          className={twMerge(sPlayer, 'absolute left-0')}
          style={{
            transform: `scale(${scale})`,
            bottom: ((scale - 1) * 100) / 2 + '%',
          }}
        >
          <ReactPlayer
            ref={ref}
            playsinline
            light={false}
            loop={false}
            controls={false}
            volume={1}
            url={url}
            muted={!playing}
            playing={playing}
            onReady={onReady}
            onError={onError}
            onProgress={handleProgress}
            onDuration={handleDuration}
            progressInterval={34}
            config={{
              playerOptions: {
                title: false,
                controls: false,
              },
            }}
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'bottom',
              position: 'relative',
              inset: 0,
              zIndex: 1,
            }}
          />
        </div>
        {!hideTrack && (
          <Track
            loading={loading}
            progress={progress}
            lastSecond={lastSecond}
          />
        )}
        {!hideSubtitles && (
          <Subtitles
            playedSeconds={progress?.playedSeconds}
            subtitles={subtitles}
          />
        )}

        {!disableControls && (
          <Controls loading={loading}>
            {(!interactions || (!playing && !isFinished)) && (
              <IconButtonCircle
                label={t('controls.play')}
                icon="player-play-filled"
                onClick={() => onControlPlay(true)}
              />
            )}
            {!!playing && !!interactions && (
              <IconButtonCircle
                label={t('controls.pause')}
                icon="player-pause-filled"
                onClick={() => onControlPlay(false)}
              />
            )}
            {!playing && isFinished && !!interactions && (
              <IconButtonCircle
                label={t('controls.repeat')}
                icon="repeat"
                onClick={onControlRepeat}
              />
            )}
          </Controls>
        )}
      </div>

      <Loading
        loading={loading}
        placeholderImage={placeholderImage}
        disableDimmer={disableDimmer}
        error={error}
      />

      <img
        className={twMerge(
          sAbsolute,
          sTransition,
          'object-cover',
          'z-[15]',
          !playing ? 'opacity-100' : 'opacity-0',
          forceAutoPlay && 'opacity-0'
        )}
        src="/images/video-skipped.jpg"
      />

      {error && <div className={twMerge(sAbsolute, 'bg-white/20')} />}

      {disableControls && (
        <div
          onClick={() => {
            if (isFinished) setSeekTo(0, 'seconds')
            setPlaying((prev) => !prev)
          }}
          className={twMerge(sAbsolute, 'z-[30]')}
        />
      )}

      {/* {dev && (
        <Performance
          progress={progress}
          playing={playing}
          loading={loading}
          error={error}
          isOverlay={isOverlay}
        />
      )} */}
    </>
  )
}
