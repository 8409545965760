import useConfig from '@api/useConfig'
import { useMemo } from 'react'
import { getCurrencyFormatter } from '@utils/currencyFormat'

const useCurrencyFormat = (options?: Intl.NumberFormatOptions) => {
  const config = useConfig()

  const formatter = useMemo(() => {
    return getCurrencyFormatter(
      config.data?.locale || 'de',
      config.data?.currency || 'EUR',
      options
    )
  }, [config.data?.locale, config.data?.currency])

  return formatter.format
}

export const currencySign: Record<string, string> = {
  EUR: '€',
  PLN: 'zł',
}

export const useCurrencySign = () => {
  const config = useConfig()
  return currencySign[config.data?.currency || 'EUR']
}

export default useCurrencyFormat
