import ButtonOrLink from '@ui/atoms/Button/ButtonOrLink'
import { OptionTintingProps as Props } from './types'
import { twMerge } from 'tailwind-merge'
import Typography from '@ui/atoms/Typography'
import Price from '@ui/molecules/Price'
import { sTransition } from './styles'
import TintingPreview from '@ui/organisms/TintingPreview'
import ColorAttribute from '@ui/atoms/ColorAttribute'

const width = 357
const height = 381

export default function OptionTinting({
  title,
  desc,
  color,
  intensity,
  price,
  ...props
}: Props) {
  return (
    <ButtonOrLink {...props} className="group relative inset-0">
      <div
        className={twMerge(
          sTransition,
          'relative inset-0',
          'overflow-hidden',
          'flex flex-col justify-between',
          'w-full rounded-[2rem]'
        )}
        style={{ width, height }}
      >
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[10] h-full w-full rounded-[inherit]',
            'shadow-border-black10'
          )}
        />
        <div
          className={twMerge(
            'absolute left-0 top-0 z-[1] h-full w-full rounded-[inherit]',
            'bg-gradient-to-b from-gray-75 to-white/0'
          )}
        />
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[1] h-full w-full rounded-[inherit]',
            'bg-gradient-to-b from-gray-200 to-white/0',
            'opacity-0 group-hover:opacity-50 group-active:opacity-100'
          )}
        />
        <div className="absolute left-1/2 top-1/2 z-[5] flex h-[100px] w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center">
          <TintingPreview
            scale={1.1}
            color={color}
            intensity={intensity}
            className={twMerge(
              sTransition,
              'object-contain object-center',
              'group-hover:scale-125 group-active:scale-125'
            )}
          />
        </div>
        {color && (
          <div className="absolute -bottom-16 left-1/2 z-[0] h-full w-full -translate-x-1/2 translate-y-1/2 blur-3xl">
            <ColorAttribute
              color={color}
              className={twMerge(
                sTransition,
                'h-auto w-full object-contain object-bottom',
                'group-hover:scale-125 group-active:scale-125',
                'opacity-25 group-hover:opacity-50 group-active:opacity-75'
              )}
            />
          </div>
        )}

        <div className="relative inset-0 z-[5] flex h-full flex-col items-center justify-between">
          <div className="flex flex-col items-center gap-3 p-8">
            <Typography
              variant="LGB"
              className="text-center leading-[1.1em] tracking-[-0.05em]"
            >
              {title}
            </Typography>
            <Typography
              variant="SM"
              className="text-center leading-tight text-text/70"
            >
              {desc}
            </Typography>
          </div>
          <div className="flex w-full items-center justify-center p-8">
            <Price
              {...price}
              colorCustom={color?.front?.[0]?.color || undefined}
            />
          </div>
        </div>
      </div>
    </ButtonOrLink>
  )
}
