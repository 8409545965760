import { twMerge } from 'tailwind-merge'

interface Props {
  children: React.ReactNode
  loading: boolean
}

export default function Controls({ children }: Props) {
  return (
    <div
      className={twMerge(
        'absolute right-0 top-1/2 flex w-1/2 -translate-y-1/2 translate-x-full flex-col items-center justify-center gap-12'
      )}
    >
      {children}
    </div>
  )
}
