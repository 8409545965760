import { cva } from 'class-variance-authority'

export const sButton = cva(
  [
    'z-1 relative inset-0',
    'flex shrink-0 grow-0 items-center justify-center',
    'box-border rounded-full',
    'font-button leading-none',
    'tracking-[0.05em]',
    'outline-none transition-all duration-300 ease-in-out',
  ],
  {
    variants: {
      variant: {
        filled: [
          'font-[700]',
          'text-white',
          'bg-gray-900',
          'hover:bg-gray-800 group-hover:bg-gray-800',
          'active:bg-black group-active:bg-black',
        ],
        outlined: [
          'font-[760]',
          'text-gray-900',
          'hover:bg-text/10 group-hover:bg-text/10',
          'active:bg-text/25 group-active:bg-text/25',
        ],
      },
      size: {
        lg: 'h-16 gap-4 px-8 text-[1.25rem]',
        md: 'h-12 gap-2 px-4 text-[1rem]',
        sm: 'h-10 px-6 text-[0.875rem]',
        xs: 'h-8 px-4 text-[0.75rem]',
      },
      width: {
        minimal: 'min-w-[15rem] max-w-max',
        content: 'w-max min-w-min max-w-max',
        full: 'w-full',
        icon: '',
      },
      icon: {
        none: '',
        start: '',
        end: '',
        both: '',
        only: '',
      },
      disabled: {
        true: 'pointer-events-none cursor-not-allowed bg-neutral-300 text-black/25',
        false: '',
      },
    },
    compoundVariants: [
      // outlined
      {
        variant: 'outlined',
        disabled: false,
        className:
          'border-2 border-text hover:border-gray-800 active:border-black group-hover:border-gray-800 group-active:border-black',
      },
      // outlined xs border
      {
        variant: 'outlined',
        size: 'xs',
        disabled: false,
        className: 'border border-text/25',
      },
      // lg iconStart
      {
        size: 'lg',
        icon: 'start',
        className: 'gap-4 pl-8 pr-12',
      },
      // lg iconEnd
      {
        size: 'lg',
        icon: 'end',
        className: 'gap-4 pl-12 pr-8',
      },
      // lg icon both
      {
        size: 'lg',
        icon: 'both',
        className: 'gap-4 px-8',
      },
      // lg icon none
      {
        size: 'lg',
        icon: 'none',
        className: 'px-12',
      },
      // lg icon only
      {
        size: 'lg',
        icon: 'only',
        className: 'w-16 justify-center px-0',
      },
      // md iconStart
      {
        size: 'md',
        icon: 'start',
        className: 'gap-4 pl-4 pr-8',
        // className: 'pl-8 pr-8',
      },
      // md iconEnd
      {
        size: 'md',
        icon: 'end',
        className: 'gap-4 pl-8 pr-4',
        // className: 'pl-8 pr-8',
      },
      // md icon both
      {
        size: 'md',
        icon: 'both',
        className: 'gap-4 px-4',
      },
      // md icon none
      {
        size: 'md',
        icon: 'none',
        className: 'px-8',
      },
      // md icon only
      {
        size: 'md',
        icon: 'only',
        className: 'min-w-12 max-w-12 w-12 justify-center px-0',
      },
      // sm icon only
      {
        size: 'sm',
        icon: 'only',
        className: 'min-w-10 max-w-10 w-10 justify-center px-0',
      },
      // xs icon only
      {
        size: 'xs',
        icon: 'only',
        className: 'min-w-8 max-w-8 w-8 justify-center px-0',
      },
      // icon only disabled
      {
        icon: 'only',
        className: 'border border-black/10',
      },
      // icon only disabled
      {
        icon: 'only',
        disabled: true,
        className: 'border-text/0',
      },
    ],
    defaultVariants: {
      variant: 'filled',
      disabled: false,
      width: 'content',
      size: 'md',
    },
  }
)
