import paramPath from './utils/paramPath'
export * from './utils/paramPath'

const elaiVideos = {
  byId: paramPath<{ id: string }>('/videos/elai/:id'),
  export: '/videos/elai/export',
}

export const endpoints = {
  auth: '/auth',
  videos: {
    list: '/videos',
    elai: elaiVideos,
    createTus: '/videos/tus/create/',
    subtitles: paramPath<{ id: string }>('/videos/:id/subtitles'),
    transfer: paramPath<{ id: string }>('/videos/transfer/:id'),
    update: {
      process: '/videos/update/process',
      verify: '/videos/update/verify',
      byId: paramPath<{ id: string }>('/videos/update/:id'),
    },
    patch: {
      byId: paramPath<{ id: string }>('/videos/patch/:id'),
    },
  },
  migrations: {
    migrate: '/migrations/migrate',
  },
  translations: {
    export: '/translations/export',
  },
  steps: {
    listByLocale: paramPath<{ locale: string }>('/steps/locale/:locale'),
  },
  logs: {
    result: '/logs/result',
  },
} as const
