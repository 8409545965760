import ButtonOrLink from '@ui/atoms/Button/ButtonOrLink'
import { OptionColorProps } from './types'
import { twMerge } from 'tailwind-merge'
import Typography from '@ui/atoms/Typography'
import ColorAttribute from '@ui/atoms/ColorAttribute'

const width = 359
const sTransition = 'transition-all duration-300'

export default function OptionColor({
  title,
  desc,
  color,
  ...props
}: OptionColorProps) {
  return (
    <ButtonOrLink {...props} className="group">
      <div
        className={twMerge(
          sTransition,
          'relative inset-0',
          'overflow-hidden',
          'flex flex-row items-center justify-between',
          'w-full rounded-[1.5rem]',
          'p-8'
        )}
        style={{ width }}
      >
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[1] h-full w-full rounded-[inherit]',
            'bg-gradient-to-r from-gray-75 to-white/0'
          )}
        />
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[1] h-full w-full rounded-[inherit]',
            'bg-gradient-to-r from-gray-200 to-white/0',
            'opacity-0 group-hover:opacity-50 group-active:opacity-100'
          )}
        />
        <ColorAttribute
          size={128}
          color={color}
          className={twMerge(
            sTransition,
            'absolute right-12 top-1/2 z-[1] -translate-y-1/2 translate-x-1/2 scale-x-[2] group-hover:scale-x-[2.5] group-active:scale-x-[3]'
          )}
        />
        <div className="absolute left-0 top-0 z-[2] h-full w-full rounded-[inherit] backdrop-blur-3xl" />
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[3] h-full w-full rounded-[inherit]',
            'bg-gradient-to-r from-gray-75 via-gray-75/75 to-gray-75/0',
            'opacity-100 group-hover:opacity-50 group-active:opacity-0'
          )}
        />
        <div className="relative inset-0 z-[5] flex flex-col gap-2">
          <Typography
            variant="LGB"
            className="text-[1.125rem] leading-none tracking-[-0.05em]"
          >
            {title}
          </Typography>
          <Typography variant="SM" className="leading-tight text-text/70">
            {desc}
          </Typography>
        </div>
        <ColorAttribute
          withWhite
          size={64}
          color={color}
          className={twMerge(
            sTransition,
            'relative inset-0 z-[5] group-hover:scale-[1.2]'
          )}
        />
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[10] h-full w-full rounded-[inherit]',
            'shadow-border-black10'
          )}
        />
      </div>
    </ButtonOrLink>
  )
}
