import useStepHistory from '@hooks/useStepHistory'
import Nav from '@ui/organisms/Nav'
import DevLayout from '@ui/wrappers/DevLayout'
import { twMerge } from 'tailwind-merge'

interface Props {
  children: React.ReactNode
  back?: React.ReactNode
}

export default function Layout({ children, back }: Props) {
  const { dev } = useStepHistory()

  return (
    <>
      {dev && <DevLayout />}
      <main className={twMerge('relative inset-0 h-full min-h-screen w-full')}>
        <Nav back={back} />
        {children}
      </main>
    </>
  )
}
