import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import useStepHistory from '@hooks/useStepHistory'
import Headline from '@ui/organisms/Headline'
import Section from '@ui/wrappers/Section'
import useOptionClick from '@hooks/useOptionClick'
import { ResolvedStepOption, StepConfig } from '@type/config'
import OptionSmall from '@ui/options/OptionSmall'
import {
  AnimateHeadline,
  AnimateOptionEntry,
  AnimateOptionEntryDelay,
} from '../styles'
import VideoContainer from '@components/Video'
import {
  useTranslatedOption,
  useTranslatedStep,
  useTranslatedVideo,
} from '@hooks/useTranslated'

export default function TemplateVideoHorizontal() {
  const { current, options, video } = useStepHistory()

  const { title, desc } = useTranslatedStep(current?.step)

  const tlVideo = useTranslatedVideo(video)

  if (!current?.step) return null
  const { step } = current

  return (
    <>
      <Headline title={title} desc={desc} className={AnimateHeadline} />
      {tlVideo?.url && (
        <Section
          classNameOuter={twMerge(AnimateHeadline, 'relative')}
          className="px-8 pb-8"
        >
          <VideoContainer
            scale={1.15}
            forceAutoPlay
            disableControls
            hideTrack
            hideSubtitles
            key={step.id}
            videoUrl={tlVideo?.url}
            elaiId={tlVideo?.elaiId}
            videoName={tlVideo?.name}
            classNameContainer="aspect-[16/9] w-full h-auto rounded-[2rem] shadow-none max-h-[381px] border border-black/10"
            stopSecond={tlVideo?.stopSecond}
            placeholderImage="/images/video-placeholder-blur.jpg"
          />
        </Section>
      )}
      <Section
        className={twMerge(
          'flex flex-wrap items-center justify-center gap-8 px-8 pb-8'
        )}
      >
        {options.map((option, index) => (
          <OptionWrapper
            key={option.title + option.value}
            index={index}
            step={step}
            option={option}
          />
        ))}
      </Section>
      {/* TODO: Recommended Button? */}
    </>
  )
}

const OptionWrapper = ({
  step,
  option,
  index,
}: {
  step: StepConfig
  option: ResolvedStepOption
  index: number
}) => {
  const { t } = useTranslation()
  const { selectOption } = useStepHistory()

  const tl = useTranslatedOption(step, option)

  const onClick = useOptionClick({
    onClick: () =>
      selectOption({
        ...option,
        ...tl,
      }),
    locked: option.locked,
    title: option.title,
    confirm: step.confirm,
  })

  const isRecommended = option.isRecommended

  return (
    <OptionSmall
      title={tl.title}
      desc={tl.desc}
      image={option.images?.[0]}
      button={{
        variant: isRecommended ? 'filled' : 'outlined',
        text: t('select'),
        size: 'sm',
      }}
      label={option.isRecommended ? t('recommended') : undefined}
      onClick={onClick}
      style={{
        animation: AnimateOptionEntry,
        animationDelay: AnimateOptionEntryDelay(index),
      }}
    />
  )
}
