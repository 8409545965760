import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { useAtomValue } from 'jotai'
import { captionsAtom } from '@jotai/captions'
import { ParsedSubtitles } from '@cwa/types'

type SubtitleProps = {
  subtitles?: ParsedSubtitles
  playedSeconds?: number
}

const OFFSET = 1000 // milliseconds

const Subtitles = ({ subtitles, playedSeconds }: SubtitleProps) => {
  const { t } = useTranslation()
  const { enabled } = useAtomValue(captionsAtom)

  const playedMs = playedSeconds ? Math.round(playedSeconds * 1000) : undefined
  const current = subtitles?.find((subtitle) => {
    return (
      playedMs != null &&
      playedMs >= subtitle.data.start - OFFSET &&
      playedMs <= subtitle.data.end - OFFSET
    )
  })

  const isHidden = !current || !enabled

  return (
    <div
      className={twMerge(
        'absolute -bottom-2 left-0 flex h-[100px] w-full translate-y-full items-end justify-center overflow-hidden pt-4'
      )}
    >
      <div
        className={twMerge(
          'flex h-full w-full items-center justify-center rounded-t-3xl bg-black/25 px-6 py-2 text-center font-bold text-white',
          'transition-transform duration-300 ease-in-out',
          isHidden ? 'translate-y-full' : 'translate-y-0'
        )}
      >
        {!!current ? current.data.text : t('captions-not-available')}
      </div>
    </div>
  )
}

export default Subtitles
