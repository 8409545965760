import { ColorBuild } from '@type/config'

export interface ColorProps {
  size?: number
  className?: string
  color?: ColorBuild
  withWhite?: boolean
}

export const linearGradientProps: React.SVGProps<SVGLinearGradientElement> = {
  x1: '32',
  y1: '0',
  x2: '32',
  y2: '64',
  gradientUnits: 'userSpaceOnUse',
}
