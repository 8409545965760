import { Config, StepOptionValue } from '@type/config'

const useColors = ({
  config,
  values,
}: {
  config: Config
  values: Record<string, StepOptionValue>
}) => {
  const isPolarlite = values.function?.value === 'polarlite'
  const isPhotolite = values.function?.value === 'photolite'

  const type = values.colors?.value

  return config.colors.filter((color) => {
    if (isPolarlite && !color.polarlite) {
      return false
    }
    if (isPhotolite && !color.photolite) {
      return false
    }
    // For fashion colors we only show photolite colors for photolite and vice versa
    if (color.type === 'fashion' && color.photolite !== isPhotolite) {
      return false
    }

    if (type && type !== color.type) {
      return false
    }

    return true
  })
}

export const useColor = ({
  config,
  values,
}: {
  config: Config
  values: Record<string, StepOptionValue>
}) => {
  return config.colors.find((color) => values.color?.value === color.code)
}

export default useColors
