import { twMerge } from 'tailwind-merge'
import ButtonOrLink from './ButtonOrLink'
import { ButtonProps, getIconVariant } from './types'
import { sButton } from './styles'
import Icon from '@ui/atoms/Icon'

export default function Button({
  text,
  iconStart,
  iconEnd,
  variant = 'filled',
  disabled = false,
  width = 'content',
  size = 'md',
  className,
  loading,
  noninteractive,
  ...props
}: ButtonProps) {
  const iconVariant = getIconVariant(iconStart, iconEnd, !!text)
  const iconSize = size === 'xs' ? 20 : 24
  const iconSpace = width === 'full' ? <div /> : null

  const isDisabled = disabled || loading || noninteractive

  return (
    <ButtonOrLink
      className={twMerge(
        sButton({
          variant,
          disabled: disabled,
          width: iconVariant === 'only' ? 'icon' : width,
          size,
          icon: iconVariant,
        }),
        isDisabled && 'pointer-events-none',
        className
      )}
      disabled={isDisabled}
      {...props}
    >
      {loading && (
        <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
          <Icon className="animate-spin" name="loader-2" size={32} stroke={2} />
        </div>
      )}
      {iconStart ? <Icon name={iconStart} size={iconSize} /> : iconSpace}
      {text && (
        <span
          className={twMerge(
            'w-full text-center font-button transition-opacity',
            loading && 'opacity-0'
          )}
        >
          {text}
        </span>
      )}
      {iconEnd ? <Icon name={iconEnd} size={iconSize} /> : iconSpace}
    </ButtonOrLink>
  )
}
