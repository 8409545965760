export const hex = (hex: string, alpha: number): string => {
  const hexAlpha = Math.round(255 * alpha).toString(16)
  return hex + hexAlpha
}

export const opacityFromHex = (hex: string) => {
  const nohash = hex.replace('#', '')
  if (nohash.length === 6) return 1
  return parseInt(nohash.slice(6, 8), 16) / 255
}

export const getGradientBreakpoint = (
  index: number,
  total: number,
  start?: number, // <0-1>
  end?: number // <0-1>
) => {
  if (index === 0) return start || 0.1
  if (index === total - 1) return end || 0.9
  return index / total
}
