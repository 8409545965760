import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'

import eventApi from '@api/eventApi'
import ButtonIcon from '@ui/atoms/ButtonIcon'
import Section from '@ui/wrappers/Section'
import { useModals } from '@hooks/useModals'
import TopSpace from '@ui/atoms/TopSpace'
import ButtonOrLink from '@ui/atoms/Button/ButtonOrLink'
import Typography from '@ui/atoms/Typography'
import useStepHistory from '@hooks/useStepHistory'
import useScroll from '@hooks/useScroll'

interface Props {
  back?: React.ReactNode
}

export default function Nav({ back }: Props) {
  const { t } = useTranslation()
  const { toggleModal } = useModals()
  const { frame } = useStepHistory()
  const { isScrolled } = useScroll()

  const page = `${t('glasses', { number: frame?.number || '1' })}`

  return (
    <>
      <TopSpace />
      <Section classNameOuter="fixed z-[200] inset-0 h-[var(--nav-height)]">
        <nav
          className={twMerge(
            'flex h-[var(--nav-height)] items-center justify-between bg-white/50 px-8',
            // 'backdrop-blur-xl', // MIXED
            'bg-transparent text-white transition-colors duration-200', // MIXED
            isScrolled && 'bg-white text-text' // MIXED
          )}
        >
          <div className="flex items-center gap-4">{back}</div>
          <ButtonOrLink
            onClick={() => toggleModal('frame')}
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 px-16 py-2"
          >
            <Typography variant="MDB" className={twMerge('text-center')}>
              {page}
            </Typography>
          </ButtonOrLink>
          <div className="flex items-center gap-4">
            <ButtonIcon
              icon={{ name: 'shopping-bag' }}
              onClick={() => toggleModal('summary')}
            />
            <ButtonIcon icon={{ name: 'logout' }} onClick={eventApi.logOut} />
          </div>
        </nav>
      </Section>
    </>
  )
}
