import { Suspense, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import eventApi from '@api/eventApi'
import useStepHistory from '@hooks/useStepHistory'
import { templatesMap } from '@type/templates'
import Back from '@ui/molecules/Back'
import Layout from '@ui/wrappers/Layout'
import LayoutAdmin from '@components/LayoutAdmin/LayoutAdmin'
import Summary from '@components/Summary/Summary'
import FramePreview from '@components/FramePreview/FramePreview'
import StepInfo from '@ui/molecules/StepInfo/StepInfo'

export default function Step() {
  const { t } = useTranslation()
  const { current, hasBack, back, dev, history } = useStepHistory()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const params = new URLSearchParams(searchParams)
    // if (!hasBack) {
    //   params.set('showBack', '1')
    // } else {
    //   params.delete('showBack')
    // }

    setSearchParams(params)
  }, [hasBack])

  if (!current?.step) return null

  const Template = templatesMap[current.step.template]

  if (!Template) {
    console.error(`Template ${current.step.template} not found`)
    return null
  }

  return (
    <LayoutAdmin showLocale hideNavigation={!dev} noAuth>
      <Layout
        back={
          // If hasBack is true, then we use the back function from useStepHistory
          hasBack ? <Back onClick={back} /> : <Back onClick={eventApi.goBack} />
        }
      >
        <Suspense fallback={null}>
          <StepInfo step={current.step} />

          <div className="relative mx-auto min-h-[var(--max-height-template)] w-[var(--max-width)]">
            <div className="pointer-events-none absolute inset-4 top-0 rounded-[2rem] bg-white" />
            <div className="relative h-full">
              <Template key={current?.step.id} />
            </div>
          </div>
        </Suspense>
        <Summary history={history} />
        <FramePreview />
      </Layout>
    </LayoutAdmin>
  )
}
