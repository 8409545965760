import { useEffect, useState } from 'react'

export const useScroll = () => {
  const [scrollY, setScrollY] = useState(0)
  const [isScrolled, setIsScrolled] = useState(false)

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    setScrollY(currentScrollY)
    setIsScrolled(currentScrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return { scrollY, isScrolled }
}

export default useScroll
