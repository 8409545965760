import Version from '@ui/atoms/Version'
import { twMerge } from 'tailwind-merge'

const border = 'border-2 border-neutral-200 border-dashed'
const fixed = 'fixed left-1/2 top-0 -translate-x-1/2'
const pointer = 'pointer-events-none'
const container = 'h-[var(--max-height)] w-[var(--max-width)] rounded-[2rem]'

export default function DevLayout() {
  return (
    <>
      {/* <div className={twMerge(fixed, pointer, 'bg-black', 'h-full w-full')} />
      <div className={twMerge(fixed, container, pointer, 'bg-white')} /> */}
      <div className={twMerge(fixed, container, border, pointer)} />
      <Version />
    </>
  )
}
