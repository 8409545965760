import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getSummaryFromParamValues,
  getValuesListFromHistory,
} from '@utils/optionUtils'
import { StepHistory } from '@type/StepHistory'
import Modal from '@ui/organisms/Modal/Modal'
import { useModals } from '@hooks/useModals'
import Typography from '@ui/atoms/Typography'
import CartItem from './CartItem'
import Button from '@ui/atoms/Button'
import useCurrencyFormat from '@hooks/useCurrencyFormat'

interface Props {
  history: StepHistory
}

const Summary = ({ history }: Props) => {
  const { t } = useTranslation()
  const currencyFormat = useCurrencyFormat({
    // @ts-expect-error This is missing from ts definition
    trailingZeroDisplay: 'auto',
  })
  const { modal, setModal } = useModals()

  // TODO: consider sorting options by related frame?
  const { total, selected } = useMemo(
    () =>
      getSummaryFromParamValues(getValuesListFromHistory(history, true), true),
    [history]
  )

  if (total === 0)
    return (
      <Modal
        open={modal === 'summary'}
        title={t('summary.empty.title')}
        description={t('summary.empty.desc')}
        onClose={() => setModal(null)}
      >
        <div className="flex flex-row px-16 py-12">
          <Button
            text={t('back')}
            variant="outlined"
            iconStart="arrow-narrow-left"
            onClick={() => setModal(null)}
          />
        </div>
      </Modal>
    )

  return (
    <Modal
      open={modal === 'summary'}
      title={t('summary.order.title')}
      description={t('summary.order.desc')}
      onClose={() => setModal(null)}
    >
      <div className="flex flex-col gap-12 py-12">
        <div className="flex flex-col items-start gap-0">
          {selected.map((option) => {
            const name = option.label || option.value
            const attribute = option.attribute
            const price = option.price
            return (
              <CartItem
                key={name + price}
                name={name}
                attribute={attribute}
                price={price}
              />
            )
          })}
        </div>
        <div className="flex flex-row items-center justify-between px-16">
          <Button
            text={t('back')}
            variant="outlined"
            iconStart="arrow-narrow-left"
            onClick={() => setModal(null)}
          />
          <div className="flex flex-col items-end">
            <Typography variant="XSB" className="uppercase text-text/25">
              {t('summary.order.cost')}
            </Typography>
            <Typography variant="XLB" className="text-gray-900">
              {currencyFormat(total)}
            </Typography>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Summary
