import { VariantProps } from 'class-variance-authority'
import { ComponentProps } from 'react'
import { sButton } from './styles'
import { LinkProps } from 'react-router-dom'
import { IconNameProp } from '../Icon/types'

export type ButtonOrLinkProps = ComponentProps<'button'> &
  ComponentProps<'a'> &
  Partial<LinkProps>

export interface ButtonProps
  extends ButtonOrLinkProps,
    Omit<VariantProps<typeof sButton>, 'disabled'> {
  text?: string
  iconStart?: IconNameProp
  iconEnd?: IconNameProp
  loading?: boolean
  noninteractive?: boolean
}

export const getIconVariant = (
  icon1?: IconNameProp,
  icon2?: IconNameProp,
  text?: boolean
) => {
  if (!text) return 'only'
  if (icon1 && icon2) return 'both'
  if (icon1) return 'start'
  if (icon2) return 'end'
  return 'none'
}
