import { useTranslation } from 'react-i18next'
import Icon from '@ui/atoms/Icon'
import { IconNameProp } from '@ui/atoms/Icon/types'
import Typography from '@ui/atoms/Typography'
import useCurrencyFormat from '@hooks/useCurrencyFormat'

interface Props {
  name: string
  attribute: string
  price?: number | null
}

const iconAttribute = (_a: string): IconNameProp => {
  const a = _a.toLowerCase()

  if (a.includes('glasstype')) return 'eye'
  if (a.includes('package')) return 'diamond'
  if (a.includes('strategy')) return 'viewfinder'
  if (a.includes('function')) return 'background'
  if (a.includes('frame')) return 'eye-glass2'
  if (a.includes('index')) return 'arrows-vertical'
  if (a.includes('coating')) return 'droplet'
  if (a.includes('filter')) return 'background'
  if (a.includes('tinting')) return 'sun-high'
  if (a.includes('stock')) return 'box-seam'
  if (a.includes('color')) return 'palette'
  if (a.includes('far')) return 'arrows-maximize'
  if (a.includes('near')) return 'arrows-minimize'
  return 'circle'
}

export default function CartItem({ name, attribute, price }: Props) {
  const { t, i18n } = useTranslation()
  const currencyFormat = useCurrencyFormat()
  const icon = iconAttribute(attribute)

  const isIncluded = price === 0

  if (!i18n.exists(`attributes.${attribute}`)) {
    return null
  }

  return (
    <div className="borer-black/10 flex w-full flex-row items-center justify-between border-b px-16 py-6 first-of-type:border-y">
      <div className="flex flex-row items-center gap-8">
        <Icon name={icon} size={24} />
        <div>
          <Typography variant="MDB" className="text-text">
            {t(`attributes.${attribute}`)}
          </Typography>
          <Typography variant="SM" className="text-text/50">
            {name}
          </Typography>
        </div>
      </div>
      {isIncluded && (
        <div className="flex h-8 items-center justify-center rounded-lg bg-white px-2">
          <Typography variant="SMB" className="uppercase text-text/25">
            {t('included')}
          </Typography>
        </div>
      )}
      {!isIncluded && price != null && (
        <div className="flex h-8 items-center justify-center rounded-lg bg-gray-75 px-4">
          <Typography variant="MDB" className="text-gray-900">
            + {currencyFormat(price)}
          </Typography>
        </div>
      )}
    </div>
  )
}
