import { twMerge } from 'tailwind-merge'
import { Progress } from '../types'

interface Props {
  loading: boolean
  progress: Progress
  lastSecond: number
}

const sHeight = 'h-2'
const sLine = 'pointer-events-none absolute left-0 bottom-0 z-[30]' // 'transition-all duration-200 ease-linear',

export default function Track({ loading, progress, lastSecond }: Props) {
  if (loading) return null

  const played = Math.min(progress.playedSeconds / lastSecond, 1)
  const loaded = Math.min(progress.loadedSeconds / lastSecond, 1)

  return (
    <div className="pointer-events-none absolute left-0 top-[0] z-[30] flex h-full w-full animate-fadein rounded-3xl">
      <div className="relative inset-0 w-full overflow-hidden">
        <div className={twMerge(sLine, sHeight, 'z-[29] w-full bg-white/0')} />
        <div
          className={twMerge(
            sLine,
            sHeight,
            'bg-white/0',
            progress.playedSeconds >= lastSecond && 'duration-0'
          )}
          style={{ width: loaded * 100 + '%' }}
        />
        <div
          className={twMerge(
            sLine,
            sHeight,
            'bg-black/50',
            progress.playedSeconds >= lastSecond && 'duration-0',
            progress.playedSeconds <= 0.5 && 'opacity-0'
          )}
          style={{ width: played * 100 + '%' }}
        />
      </div>
    </div>
  )
}
