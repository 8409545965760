import { getStrapiStepUrl } from '@utils/urls'
import { StepConfig } from '@type/config'
import ExternalLink from '@/pages/matcher/ExternalLink'
import Info from '@ui/atoms/Info'
import { twMerge } from 'tailwind-merge'

const StepInfo = ({ step }: { step: StepConfig }) => (
  <Info>
    <ExternalLink
      className={twMerge('text-text/25 hover:text-blue-500', 'text-white/50')} // MIXED
      href={getStrapiStepUrl(step)}
    >
      {step.name}
    </ExternalLink>
  </Info>
)

export default StepInfo
