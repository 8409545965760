import React from 'react'
import Paper from '@ui/atoms/Paper'
import Typography from '@ui/atoms/Typography'
import Section from '../wrappers/Section'
import Button from '@ui/atoms/Button'

const DisplayError = ({ children }: { children: React.ReactNode }) => (
  <Section className="p-8">
    <Paper className="flex flex-col gap-8 p-8">
      <div className="flex flex-col gap-2">
        <Typography variant="PM" color="text-red-500" className="uppercase">
          Error
        </Typography>
        <Typography className="font-text font-normal">{children}</Typography>
      </div>
      <Button size="sm" text="Restart" href="/" />
    </Paper>
  </Section>
)

export default DisplayError
