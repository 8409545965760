import { twMerge } from 'tailwind-merge'
import Icon from '@ui/atoms/Icon'
import { sAbsolute } from '../styles'

interface Props {
  loading: boolean
  placeholderImage?: string
  disableDimmer?: boolean
  skipped?: boolean
  error?: boolean
}

export default function Loading({
  loading,
  placeholderImage,
  disableDimmer,
  skipped,
  error,
}: Props) {
  return (
    <div
      className={twMerge(
        sAbsolute,
        'pointer-events-none flex items-center justify-center overflow-hidden transition-opacity duration-500 ease-out',
        !skipped && 'animate-fadein',
        !loading && !skipped && 'opacity-0',
        error && 'opacity-0'
      )}
    >
      {/* {placeholderImage && (
        <img
          className={twMerge(sAbsolute, 'object-cover')}
          src={placeholderImage}
        />
      )} */}
      {!disableDimmer && (
        <div className={twMerge(sAbsolute, 'animate-pulse bg-black/50')} />
      )}

      <div className="animate-[fadein_1s_ease-out_2s_both]">
        <Icon
          name="loader-2"
          color="text-black/50"
          size={64}
          className={twMerge(
            'animate-[spin_1s_infinite_linear] transition-opacity',
            !loading && 'opacity-0 paused'
          )}
        />
      </div>
    </div>
  )
}
