import { twMerge } from 'tailwind-merge'
import Typography from '@ui/atoms/Typography'
import useStepHistory from '@hooks/useStepHistory'
import Section from '@ui/wrappers/Section'
interface Props {
  children: React.ReactNode
  className?: string
}

export default function Info({ children, className }: Props) {
  const { dev } = useStepHistory()
  if (!dev) return null

  return (
    <Section
      classNameOuter="pointer-events-none absolute top-0 left-1/2 z-[205] -translate-x-1/2"
      className="flex h-[var(--nav-height)] items-center justify-center"
    >
      <Typography
        variant="SMB"
        className={twMerge(
          'pointer-events-auto relative inset-0 top-6 w-max select-text rounded-md px-2 py-2',
          className
        )}
      >
        {children}
      </Typography>
    </Section>
  )
}
