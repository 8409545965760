import { PriceColor } from './types'

export const priceGradient: Record<PriceColor, string> = {
  default: 'bg-gradient-to-r from-text/75 to-text',
  gray: 'bg-gradient-to-r from-gray-500 via-gray-600 to-gray-800',
  green: 'bg-gradient-to-r from-lime-500 via-lime-600 to-lime-800',
  magenta: 'bg-gradient-to-r from-magenta-500 via-magenta-600 to-magenta-800',
  blue: 'bg-gradient-to-r from-blue-500 via-blue-600 to-blue-800',
}

export const pricePrefixColor: Record<PriceColor, string> = {
  default: 'text-text/50',
  gray: 'text-gray-500',
  green: 'text-lime-600',
  magenta: 'text-magenta-600',
  blue: 'text-blue-600',
}

export const priceIncluded: Record<PriceColor, string> = {
  default: 'bg-text/10 text-text/50',
  gray: 'bg-gray-500/10 text-gray-500',
  green: 'bg-lime-600/10 text-lime-600',
  magenta: 'bg-magenta-600/10 text-magenta-600',
  blue: 'bg-blue-600/10 text-blue-600',
}
