import { useConfirmModal } from '@hooks/useConfirm'
import Button from '@ui/atoms/Button'
import Typography from '@ui/atoms/Typography'
import Modal from '@ui/organisms/Modal/Modal'
import { useTranslation } from 'react-i18next'

const ConfirmModal = () => {
  const { isOpen, confirm, close, title } = useConfirmModal()
  const { t } = useTranslation()

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title={t(`confirm.title`)}
      description={
        <div className="flex flex-col gap-12">
          <Typography color="text-text/50" variant="MD">
            {t(`confirm.desc`)}
          </Typography>
          <div className="flex items-center justify-center rounded-[2rem] border border-black/10 bg-gray-75 p-8 py-12">
            <Typography
              variant="LG"
              className="text-center uppercase tracking-[0.1em] text-text"
            >
              {title}
            </Typography>
          </div>
        </div>
      }
    >
      <div className="flex w-full flex-row items-center justify-between gap-8 px-16 py-12">
        <Button
          variant="filled"
          onClick={confirm}
          text={t(`confirm.next`)}
          iconEnd="check"
          className="capitalize"
        />
        <Button
          variant="outlined"
          onClick={close}
          text={t(`confirm.cancel`)}
          iconEnd="x"
          className="capitalize"
        />
      </div>
    </Modal>
  )
}

export default ConfirmModal
