import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import useStepHistory from '@hooks/useStepHistory'
import Headline from '@ui/organisms/Headline'
import Section from '@ui/wrappers/Section'
import OptionLarge from '@ui/options/OptionLarge'
import useOptionClick from '@hooks/useOptionClick'
import { ResolvedStepOption, StepConfig } from '@type/config'
import {
  AnimateHeadline,
  AnimateOptionEntry,
  AnimateOptionEntryDelay,
} from '../styles'
import { useCurrencySign } from '@hooks/useCurrencyFormat'
import { useTranslatedOption, useTranslatedStep } from '@hooks/useTranslated'

export default function TemplateLarge() {
  const { current, options } = useStepHistory()

  const { title, desc } = useTranslatedStep(current?.step)
  if (!current?.step) return null
  const { step } = current

  const hasPrice = options.some((o) => o.price || o.priceText)

  return (
    <>
      <Headline title={title} desc={desc} className={AnimateHeadline} />
      <Section className={twMerge('flex flex-col gap-8 px-8 pb-8')}>
        {options.map((option, index) => (
          <OptionWrapper
            hasPrice={hasPrice}
            key={option.title + option.value}
            index={index}
            step={step}
            option={option}
          />
        ))}
      </Section>
    </>
  )
}

const OptionWrapper = ({
  index,
  step,
  option,
  hasPrice,
}: {
  index: number
  step: StepConfig
  option: ResolvedStepOption
  hasPrice: boolean
}) => {
  const { t } = useTranslation()
  const { selectOption } = useStepHistory()
  const currencySign = useCurrencySign()

  const tl = useTranslatedOption(step, option)

  const onClick = useOptionClick({
    onClick: () =>
      selectOption({
        ...option,
        ...tl,
      }),
    locked: option.locked,
    title: option.title,
    confirm: step.confirm,
  })

  return (
    <OptionLarge
      title={tl.title}
      desc={tl.desc}
      image={option.images?.[0]}
      button={
        hasPrice
          ? undefined
          : {
              text: option.cta || t('select'),
              className: 'capitalize',
            }
      }
      price={
        hasPrice
          ? {
              value: option.price,
              sign: currencySign,
              prefix: tl.pricePrefix,
              text: tl.priceText,
            }
          : undefined
      }
      onClick={onClick}
      disabled={option.disabled}
      style={{
        animation: AnimateOptionEntry,
        animationDelay: AnimateOptionEntryDelay(index),
      }}
    />
  )
}
