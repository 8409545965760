import { cva } from 'class-variance-authority'

export const sAbsolute = 'absolute left-0 top-0 h-full w-full rounded-[inherit]'

export const sContainer =
  'relative inset-0 aspect-[9/16] h-[720px] rounded-3xl shadow-2xl'

export const sOptionsContainer = cva([
  'bottom-0 top-auto z-[20] box-border flex h-[76.5%] flex-col items-center justify-end gap-2.5 pb-3 pl-4 pr-4 pt-0 transition-opacity duration-500 ease-in-out',
])

export const sOptionContainerSimple =
  'bottom-0 top-auto z-[20] box-border h-[75%] animate-[fadein_0.5s_ease-out] px-6 pb-6 flex flex-col gap-6'

export const sOptionsBackground =
  'animate-[fadein_0.5s_ease-out] bg-gradient-to-b from-black/0 to-black/50'
