import React from 'react'
import { twMerge } from 'tailwind-merge'
import { sCenter, sLabel, sLabelBackground, sTransition } from './styles'
import Typography from '@ui/atoms/Typography'

interface Props {
  children: React.ReactNode
  color?: string
}

export default function Label({ children, color }: Props) {
  return (
    <>
      <div
        className={twMerge(
          sTransition,
          sLabelBackground,
          'absolute left-1/2 top-0 z-[0] -translate-x-1/2 -translate-y-1/2',
          'flex h-8 max-h-[2rem] w-max items-center justify-center rounded-t-[1.25rem]',
          'shadow-border-black10'
        )}
      >
        <Typography
          variant="XSB"
          component="span"
          className={twMerge(sLabel, 'opacity-0')}
        >
          {children}
        </Typography>
      </div>
      <div className={twMerge(sCenter, 'top-0 z-[12]')}>
        <Typography
          variant="XSB"
          component="span"
          className={twMerge(sLabel, color, 'relative top-1 z-[12]')}
        >
          {children}
        </Typography>
        <div
          className={twMerge(
            sTransition,
            sLabelBackground,
            'absolute left-1/2 top-1/2 z-[11] h-[2px] w-[calc(100%_-_4px)] -translate-x-1/2 -translate-y-1/2 rounded'
          )}
        />
      </div>
    </>
  )
}
