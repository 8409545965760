import { useTranslation } from 'react-i18next'
import AppConfig from 'AppConfig'
import useStepHistory from '@hooks/useStepHistory'
import { timeFromNow } from '@utils/timing'
import { twMerge } from 'tailwind-merge'
import Typography from '../Typography'

const T = ({ children, dim }: { children: React.ReactNode; dim?: boolean }) => (
  <Typography
    variant={dim ? 'XS' : 'XSB'}
    className={twMerge('select-text whitespace-nowrap', dim && 'opacity-75')}
  >
    {children}
  </Typography>
)

const O = () => <T dim>&bull;</T>

export default function Version({ className }: { className?: string }) {
  const { i18n } = useTranslation()
  const { dev } = useStepHistory()
  if (!dev) return null

  const { appName, appVersion, appBuild, appCommit, strapiUrl } = AppConfig

  return (
    <div
      className={twMerge(
        'fixed bottom-0 left-1/2 z-[100] flex -translate-x-1/2 select-text gap-2 rounded-t-lg px-4 py-1.5 text-gray-500 backdrop-blur-2xl',
        className
      )}
    >
      <T>
        {appName} {appVersion} {appCommit}
      </T>
      <O />
      <T dim>
        {new Date(appBuild).toLocaleString(i18n.language, {
          timeZone: 'Europe/Berlin',
        })}
      </T>
      <O />
      <T dim>{timeFromNow(new Date(appBuild), 'en')}</T>
      <O />
      <T dim>
        {strapiUrl.replace('https://', '').replace('http://', '').split('.')[0]}
      </T>
    </div>
  )
}
