/* Import another if needed from https://tabler-icons.io/ */

import {
  IconAdjustments,
  IconAlertCircleFilled,
  IconAlertTriangle,
  IconAlertTriangleFilled,
  IconArrowLeft,
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconArrowRight,
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconArrowsMove,
  IconArrowsVertical,
  IconBackground,
  IconBadgeCc,
  IconBan,
  IconBolt,
  IconBoxSeam,
  IconBrandVimeo,
  IconCheck,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconCircle,
  IconCircleCheck,
  IconCircleCheckFilled,
  IconCircleDotted,
  IconCircleX,
  IconDiamond,
  IconDownload,
  IconDownloadOff,
  IconDroplet,
  IconEye,
  IconEyeglass2,
  IconExclamationCircleFilled,
  IconFlagFilled,
  IconFocus2,
  IconHelpCircle,
  IconHelpCircleFilled,
  IconHome,
  IconInfoSquareFilled,
  IconKey,
  IconKeyOff,
  IconLanguage,
  IconLink,
  IconLoader,
  IconLoader2,
  IconLogout,
  IconMenu2,
  IconPalette,
  IconPencil,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconPlayerSkipBackFilled,
  IconPlayerSkipForwardFilled,
  IconPlayerTrackNextFilled,
  IconPlayerTrackPrevFilled,
  IconRepeat,
  IconSearch,
  IconSettings,
  IconSettingsFilled,
  IconShare,
  IconShoppingBag,
  IconShoppingCart,
  IconStackPush,
  IconStarFilled,
  IconSunHigh,
  IconTrash,
  IconVideo,
  IconViewfinder,
  IconWorld,
  IconX,
} from '@tabler/icons-react'

const icons = {
  'alert-circle-filled': IconAlertCircleFilled,
  'alert-triangle-filled': IconAlertTriangleFilled,
  'alert-triangle': IconAlertTriangle,
  'arrow-left': IconArrowLeft,
  'arrow-narrow-left': IconArrowNarrowLeft,
  'arrow-narrow-right': IconArrowNarrowRight,
  'arrow-right': IconArrowRight,
  'arrows-maximize': IconArrowsMaximize,
  'arrows-minimize': IconArrowsMinimize,
  'arrows-move': IconArrowsMove,
  'arrows-vertical': IconArrowsVertical,
  'badge-cc': IconBadgeCc,
  'box-seam': IconBoxSeam,
  'brand-vimeo': IconBrandVimeo,
  'chevron-down': IconChevronDown,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  'chevron-up': IconChevronUp,
  'circle-check-filled': IconCircleCheckFilled,
  'circle-check': IconCircleCheck,
  'circle-dotted': IconCircleDotted,
  'circle-x': IconCircleX,
  'download-off': IconDownloadOff,
  'eye-glass2': IconEyeglass2,
  'exclamation-circle-filled': IconExclamationCircleFilled,
  'flag-filled': IconFlagFilled,
  'focus-2': IconFocus2,
  'help-circle-filled': IconHelpCircleFilled,
  'help-circle': IconHelpCircle,
  'info-square-filled': IconInfoSquareFilled,
  'key-off': IconKeyOff,
  'loader-2': IconLoader2,
  'menu-2': IconMenu2,
  'player-pause-filled': IconPlayerPauseFilled,
  'player-play-filled': IconPlayerPlayFilled,
  'player-skip-back-filled': IconPlayerSkipBackFilled,
  'player-skip-forward-filled': IconPlayerSkipForwardFilled,
  'player-track-next-filled': IconPlayerTrackNextFilled,
  'player-track-prev-filled': IconPlayerTrackPrevFilled,
  'settings-filled': IconSettingsFilled,
  'shopping-bag': IconShoppingBag,
  'shopping-cart': IconShoppingCart,
  'stack-push': IconStackPush,
  'sun-high': IconSunHigh,
  adjustments: IconAdjustments,
  background: IconBackground,
  ban: IconBan,
  bolt: IconBolt,
  check: IconCheck,
  circle: IconCircle,
  diamond: IconDiamond,
  download: IconDownload,
  droplet: IconDroplet,
  eye: IconEye,
  home: IconHome,
  key: IconKey,
  language: IconLanguage,
  link: IconLink,
  loader: IconLoader,
  logout: IconLogout,
  palette: IconPalette,
  pencil: IconPencil,
  repeat: IconRepeat,
  search: IconSearch,
  settings: IconSettings,
  share: IconShare,
  star: IconStarFilled,
  trash: IconTrash,
  video: IconVideo,
  viewfinder: IconViewfinder,
  world: IconWorld,
  x: IconX,
} as const

export const iconNames = Object.keys(icons) as Array<keyof typeof icons>

export const iconList = Object.fromEntries(
  iconNames.map((name) => [name, name])
) as Record<keyof typeof icons, keyof typeof icons>

export default icons
