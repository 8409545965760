import ButtonOrLink from '@ui/atoms/Button/ButtonOrLink'
import { OptionSmallProps as Props } from './types'
import { twMerge } from 'tailwind-merge'
import Typography from '@ui/atoms/Typography'
import Img from '@ui/atoms/Img'
import Price from '@ui/molecules/Price'
import { sTransition } from './styles'
import Label from './Label'
import { pricePrefixColor } from '@ui/molecules/Price/styles'
import Button from '@ui/atoms/Button'

const width = 357
const height = 381

export default function OptionSmall({
  title,
  desc,
  label,
  image,
  price,
  button,
  disabled,
  ...props
}: Props) {
  return (
    <ButtonOrLink
      {...props}
      disabled={disabled}
      className={twMerge('group relative inset-0', disabled && 'grayscale')}
    >
      {label && (
        <Label color={pricePrefixColor[price?.color || 'default']}>
          {label}
        </Label>
      )}
      <div
        className={twMerge(
          sTransition,
          'relative inset-0',
          'overflow-hidden',
          'flex flex-col justify-between',
          'w-full rounded-[2rem]'
        )}
        style={{ width, height }}
      >
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[10] h-full w-full rounded-[inherit]',
            'shadow-border-black10'
          )}
        />
        <div
          className={twMerge(
            'absolute left-0 top-0 z-[1] h-full w-full rounded-[inherit]',
            'bg-gradient-to-b from-gray-75 to-white/0'
          )}
        />
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[1] h-full w-full rounded-[inherit]',
            'bg-gradient-to-b from-gray-200 to-white/0',
            'opacity-0 group-hover:opacity-50 group-active:opacity-100'
          )}
        />
        {image && (
          <>
            <div className="absolute left-1/2 top-1/2 z-[5] mt-2 h-[100px] w-full max-w-[240px] -translate-x-1/2 -translate-y-1/2">
              <Img
                image={image}
                alt={title}
                className={twMerge(
                  sTransition,
                  'h-full w-full object-contain object-center',
                  !disabled && 'group-hover:scale-125 group-active:scale-125'
                )}
              />
            </div>
            <div className="absolute bottom-0 left-1/2 z-[0] h-full w-full -translate-x-1/2 translate-y-1/2">
              <Img
                image={image}
                alt={title}
                className={twMerge(
                  sTransition,
                  'h-auto w-full object-contain object-center opacity-50 blur-[64px]',
                  !disabled &&
                    'scale-100 group-hover:scale-125 group-active:scale-150',
                  !disabled && 'group-hover:opacity-60 group-active:opacity-80'
                )}
              />
            </div>
          </>
        )}
        <div className="relative inset-0 z-[5] flex h-full flex-col items-center justify-between">
          <div className="flex flex-col items-center gap-3 p-8">
            <Typography
              variant="LGB"
              className="text-center leading-[1.1em] tracking-[-0.05em]"
            >
              {title}
            </Typography>
            <Typography
              variant="SM"
              className="text-center leading-tight text-text/70"
            >
              {desc}
            </Typography>
          </div>
          <div className="flex w-full items-center justify-center p-8">
            {button ? <Button {...button} /> : <Price {...price} />}
          </div>
        </div>
      </div>
    </ButtonOrLink>
  )
}
