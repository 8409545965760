import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import useStepHistory from '@hooks/useStepHistory'
import Headline from '@ui/organisms/Headline'
import Section from '@ui/wrappers/Section'
import useOptionClick from '@hooks/useOptionClick'
import { ColorConfig, ResolvedStepOption, StepConfig } from '@type/config'
import {
  AnimateHeadline,
  AnimateOptionEntry,
  AnimateOptionEntryDelay,
} from '../styles'
import { useColor } from '@hooks/useColors'
import OptionTinting from '@ui/options/OptionTinting'
import { useCurrencySign } from '@hooks/useCurrencyFormat'
import { intensity } from './intensity'
import { defaultColor } from '@ui/atoms/ColorAttribute'
import { useTranslatedOption, useTranslatedStep } from '@hooks/useTranslated'

export default function TemplateTinting() {
  const { config, current, options, orderValues } = useStepHistory()
  const color = useColor({ config, values: orderValues })
  const { title, desc } = useTranslatedStep(current?.step)

  if (!current?.step) return null
  const { step } = current

  return (
    <>
      <Headline title={title} desc={desc} className={AnimateHeadline} />
      <Section
        className={twMerge(
          'flex flex-wrap items-center justify-center gap-8 px-8 pb-8'
        )}
      >
        {options.map((option, index) => (
          <OptionWrapper
            key={option.id}
            index={index}
            option={option}
            step={step}
            color={color}
          />
        ))}
      </Section>
    </>
  )
}

const OptionWrapper = ({
  index,
  step,
  option,
  color,
}: {
  index: number
  step: StepConfig
  option: ResolvedStepOption
  color?: ColorConfig
}) => {
  const { t } = useTranslation()
  const { selectOption } = useStepHistory()
  const currencySign = useCurrencySign()

  const tl = useTranslatedOption(step, option)

  const onClick = useOptionClick({
    onClick: () => selectOption({ ...option, ...tl }),
    locked: option.locked,
    title: option.title,
    confirm: step.confirm,
  })

  const c = color || defaultColor

  return (
    <OptionTinting
      title={`${t(`colors.${c.name}`)} ${tl.title}`}
      desc={tl.desc}
      price={{
        value: option.price,
        sign: currencySign,
        prefix: tl.pricePrefix,
        text: tl.priceText,
      }}
      onClick={onClick}
      color={c || undefined}
      intensity={intensity[option.value]}
      style={{
        animation: AnimateOptionEntry,
        animationDelay: AnimateOptionEntryDelay(index),
      }}
    />
  )
}
