import { twMerge } from 'tailwind-merge'
import Section from '@ui/wrappers/Section'
import Typography from '@ui/atoms/Typography'

interface Props {
  title?: string
  desc?: string
  className?: string
}

const height = 190 // 192 MIXED

export default function Headline({ title, desc, className }: Props) {
  return (
    <Section classNameOuter={twMerge(className)}>
      <div
        className="flex w-full flex-col items-center gap-4 px-16 pt-8"
        style={{ height }}
      >
        <Typography
          variant="H4"
          className={twMerge(
            'line-clamp-1 text-center leading-tight tracking-tight text-text',

            'font-bold' // MIXED
          )}
        >
          {title || <>&nbsp;</>}
        </Typography>
        <Typography
          variant="MD"
          className="line-clamp-2 whitespace-pre-line text-center text-text/70"
        >
          {desc}
        </Typography>
      </div>
    </Section>
  )
}
