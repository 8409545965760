import Icon from '@ui/atoms/Icon'
import { IconNameProp, IconProps } from '@ui/atoms/Icon/types'
import { twMerge } from 'tailwind-merge'

interface Props extends Omit<IconProps, 'name'> {
  children?: React.ReactNode
  disabled?: boolean
  icon?: IconNameProp
  label?: string
  isActive?: boolean
  onClick: () => void
}

const sTransition = 'transition-all duration-200 ease-in-out'

export default function IconButtonCircle({
  children,
  disabled,
  isActive,
  icon,
  label,
  onClick,
  size = 32,
}: Props) {
  return (
    <div
      className={twMerge(
        'group flex cursor-pointer flex-col items-center justify-center gap-0',
        disabled && 'pointer-events-none'
      )}
      onClick={onClick}
    >
      <button
        type="button"
        className={twMerge(
          sTransition,
          'relative inset-0',
          'h-16 w-16 rounded-full p-4',
          'bg-black/25 text-white',
          'group-active:bg-black/50',
          disabled && 'bg-white/20 text-white/50'
        )}
      >
        {icon ? (
          <Icon name={icon} size={size} />
        ) : (
          <span className="text-3xl font-bold leading-[0.8]">{children}</span>
        )}
        <Icon
          size={32}
          name="circle-check-filled"
          className={twMerge(
            sTransition,
            'absolute -right-2 -top-2',
            isActive ? 'scale-100' : 'scale-0'
          )}
        />
      </button>
      <span
        className={twMerge(
          sTransition,
          'rounded-full px-2 pb-1 pt-0.5 text-sm font-bold',
          'text-white',
          disabled && 'text-white/50'
        )}
      >
        {label}
      </span>
    </div>
  )
}
