import ButtonIcon from '@ui/atoms/ButtonIcon'

interface Props {
  onClick?: () => void
  href?: string
}

export default function Back({ onClick, href }: Props) {
  return (
    <ButtonIcon
      href={href}
      onClick={onClick}
      icon={{ name: 'arrow-narrow-left', size: 32, stroke: 1.5 }}
    />
  )
}
