import { z } from 'zod'

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
type Literal = z.infer<typeof literalSchema>
type Json = Literal | { [key: string]: Json } | Json[]
const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
)

const jsonObjectSchema = z.record(jsonSchema)

const imageSchema = z.object({
  src: z.string(),
  width: z.number().optional(),
  height: z.number().optional(),
})

const packageConfigSchema = z.object({
  package: z.string(),
  pricePrefix: z.string().nullable(),
  promoText: z.string().nullable(),
  color: z.string().nullable(),
  logo: imageSchema.nullable().optional(),
})

const variableSchema = z.object({
  variable: z.string(),
  combinations: jsonSchema,
  scope: z.enum(['global', 'order']),
})

const conditionRelationSchema = z.object({
  selector: z.literal('relation'),
  negation: z.boolean().optional(),
  value: z.string(),
})

export const videoConfigSchema = z.object({
  id: z.number(),
  url: z.string().url().or(z.literal('')).nullable(),
  every: z.array(z.string()).nullable().optional(),
  overlayTime: z.number().nullable().optional(),
  recommended: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  overlayHeight: z.number().nullable(),
  condition: conditionRelationSchema.optional(),
  elaiId: z.string().nullable().optional(),
  elaiVersion: z.number().nullable().optional(),
})

const overrideSchema = z.object({
  id: z.number(),
  param: z.string(),
  value: z.string(),
  price: z.number().nullable().optional(),
  label: z.string().nullable().optional(),
})

const optionSchema = z.object({
  id: z.number(),
  title: z.string().nullable().optional(),
  price: z.number().nullable().optional(),
  description: z.string().nullable().optional(),
  value: z.string(),
  cta: z.string().nullable().optional(),
  label: z.string().nullable().optional(),
  body: z.string().nullable().optional(),
  priceText: z.string().nullable().optional(),
  pricePrefix: z.string().nullable().optional(),
  action: z.string().nullable().optional(),
  tlKey: z.string().nullable().optional(),
  overrides: z.record(z.string(), overrideSchema).optional(),
  nextStep: z.number().nullable().optional(),
  images: z.array(imageSchema).nullable().optional(),
  condition: conditionRelationSchema.optional(),
})

const stepSchema = z.object({
  id: z.number(),
  name: z.string(),
  template: z.string(),
  videos: z.array(videoConfigSchema).nullable().optional(),
  options: z.array(optionSchema),
  title: z.string().nullable(),
  desc: z.string().nullable(),
  variable: z.string(),
  output: z.boolean().nullable().optional(),
})

export const configSchema = z.object({
  initialStep: z.number(),
  translations: jsonObjectSchema,
  packageConfigs: z.array(packageConfigSchema).optional(),
  variables: z.array(variableSchema).optional(),
  steps: z.record(z.string(), stepSchema),
  // Hard to validate but also not that important
  conditions: jsonObjectSchema,
})

export type RemoteConfig = z.infer<typeof configSchema>

export type VideoConfig = z.infer<typeof videoConfigSchema>

export type Step = z.infer<typeof stepSchema>
export type StepOption = z.infer<typeof optionSchema>

export type StrapiStep = {
  id: number
  name: string
  locale: string
}

export type StrapiVideoConfig = Omit<
  VideoConfig,
  'condition' | 'every' | 'elaiId'
> & {
  every: string | null
  // We are only fetch videos that have elaiId
  elaiId: string
  step: StrapiStep
}

export const SupportedLocales = {
  de: 'de',
  it: 'it',
  es: 'es',
  // en: 'en',
  pl: 'pl',
} as const
