import { ColorBuild } from '@type/config'
import { getGradientBreakpoint } from '@utils/color'
import { linearGradientProps } from './types'
import { ColorProps as Props } from './types'

export const defaultColor: ColorBuild = {
  name: 'default',
  front: [{ color: '#000000', opacity: 100 }],
}

export default function Color({
  color = defaultColor,
  className,
  size = 32,
  withWhite,
}: Props) {
  const { name, front, back } = color

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      className={className}
    >
      {withWhite && <circle cx="32" cy="32" r="32" fill="white" />}
      <circle cx="32" cy="32" r="32" fill={`url(#${name}-back)`} />
      <circle cx="32" cy="32" r="32" fill={`url(#${name}-front)`} />
      <defs>
        <linearGradient id={`${name}-back`} {...linearGradientProps}>
          {back?.map(({ color, opacity }, index) => (
            <stop
              key={index}
              stopColor={color}
              stopOpacity={opacity / 100}
              offset={getGradientBreakpoint(index, back.length)}
            />
          ))}
        </linearGradient>
        <linearGradient id={`${name}-front`} {...linearGradientProps}>
          {front?.map(({ color, opacity }, index) => (
            <stop
              key={index}
              stopColor={color}
              stopOpacity={opacity / 100}
              offset={getGradientBreakpoint(index, front.length)}
            />
          ))}
        </linearGradient>
      </defs>
    </svg>
  )
}
