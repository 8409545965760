import Icon from '@ui/atoms/Icon'
import { twMerge } from 'tailwind-merge'

const sLink =
  'flex flex-row items-center justify-center gap-1 text-blue-500 hover:text-blue-600 transition-colors'

const ExternalLink = ({
  href,
  children,
  className,
}: {
  className?: string
  href: string
  children: React.ReactNode
}) => (
  <a target="_blank" href={href} className={twMerge(sLink, className)}>
    {children}
  </a>
)

export default ExternalLink
