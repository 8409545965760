import AppConfig from '@/AppConfig'
import { useModals } from '@hooks/useModals'
import useStepHistory from '@hooks/useStepHistory'
import Typography from '@ui/atoms/Typography'
import Modal from '@ui/organisms/Modal/Modal'
import { useTranslation } from 'react-i18next'

export default function FramePreview() {
  const { t } = useTranslation()
  const { modal, setModal } = useModals()
  const { frame } = useStepHistory()

  const frameId = frame?.id

  return (
    <Modal open={modal === 'frame'} onClose={() => setModal(null)}>
      <div className="flex flex-col items-center justify-center gap-16 px-16 pb-16">
        <Typography variant="XLB">
          {t('frame')} {frameId}
        </Typography>
        {frameId && (
          <img
            src={`${AppConfig.frameImagesUrl}/${frameId}`}
            alt={frameId}
            className="h-full w-full rounded-[2rem] object-contain"
          />
        )}
      </div>
    </Modal>
  )
}
