import TemplateAutomatic from '@ui/templates/Automatic/TemplateAutomatic'
import TemplateLarge from '@ui/templates/Large'
import TemplateSmall from '@ui/templates/Small'
import TemplateColor from '@ui/templates/Color'
import TemplateTinting from '@ui/templates/Tinting'
import TemplateVideoHorizontal from '@ui/templates/VideoHorizontal'
import TemplateError from '@ui/templates/Error'

export type StepTemplate =
  | 'AdImage'
  | 'AdMaxTwo'
  | 'Automatic'
  | 'Flows'
  | 'RadioColors'
  | 'RadioGroup'
  | 'RadioGroupFrame'
  | 'RadioHorizontal'
  | 'RadioHorizontalFrame'
  | 'RadioVertical'
  | 'Simple'
  | 'Video'
  | 'VideoFrame'
  | 'VideoHorizontal'
  | 'VideoImageLarge'
  | 'VideoPackage'
  | 'VideoPackage2'
  | 'VideoSquare'
  | 'Tinting'
  | 'Error'

// Small | Large | Color | Automatic | Tinting | VideoVertical

export const templatesMap: Record<StepTemplate, any> = {
  Automatic: TemplateAutomatic,
  Flows: TemplateLarge,
  RadioColors: TemplateColor,
  Video: TemplateSmall,
  Tinting: TemplateTinting,
  VideoHorizontal: TemplateVideoHorizontal,
  AdImage: TemplateSmall,
  AdMaxTwo: TemplateSmall,
  RadioGroup: TemplateSmall,
  RadioGroupFrame: TemplateSmall,
  RadioHorizontal: TemplateLarge,
  RadioHorizontalFrame: TemplateLarge,
  RadioVertical: TemplateSmall,
  Simple: TemplateSmall,
  VideoFrame: TemplateSmall,
  VideoSquare: TemplateSmall,
  VideoImageLarge: TemplateSmall,
  VideoPackage: TemplateSmall,
  VideoPackage2: TemplateSmall,
  Error: TemplateError,
}
