import { cva } from 'class-variance-authority'

export const sTypography = cva('text-left', {
  variants: {
    variant: {
      H1: ['font-head', 'text-[4rem]', 'leading-none'],
      H2: ['font-head', 'text-[3rem]', 'leading-none'],
      H3: ['font-head', 'text-[2.5rem]', 'leading-none'],
      H4: ['font-head', 'text-[2rem]', 'leading-none'],
      H5: ['font-head', 'text-[1.5rem]', 'leading-none'],
      H6: ['font-head', 'text-[1rem]', 'leading-none'],
      XL: [
        'font-text',
        'font-normal',
        'tracking-[-0.02em]', // -2%
        'text-[24px]',
        'leading-tight',
      ],
      XLB: [
        'font-text',
        'font-bold',
        'tracking-[-0.02em]', // -2%
        'text-[24px]',
        'leading-tight',
      ],
      LG: [
        'font-text',
        'font-medium',
        'tracking-[-0.02em]', // -2%
        'text-[20px]',
        'leading-tight',
      ],
      LGB: [
        'font-text',
        'font-bold',
        'tracking-[-0.02em]', // -2%
        'text-[20px]',
        'leading-tight',
      ],
      MD: [
        'font-text',
        'font-medium',
        'tracking-[-0.02em]', // -2%
        'text-[16px]',
        'leading-normal',
      ],
      MDB: [
        'font-text',
        'font-bold',
        'tracking-[-0.03em]', // -2%
        'text-[16px]',
        'leading-normal',
      ],
      SM: [
        'font-text',
        'font-medium',
        'tracking-[-0.02em]', // -2%
        'text-[14px]',
        'leading-normal',
      ],
      SMB: [
        'font-text',
        'font-bold',
        'tracking-[-0.02em]', // -2%
        'text-[14px]',
        'leading-normal',
      ],
      XS: [
        'font-text',
        'font-medium',
        'tracking-[-0.02em]', // -2%
        'text-[12px]',
        'leading-normal',
      ],
      XSB: [
        'font-text',
        'font-bold',
        'tracking-[-0.02em]', // -2%
        'text-[12px]',
        'leading-normal',
      ],

      // Deprecated
      P: ['font-text', 'font-bold', 'text-[1.125rem]', 'leading-[1.5rem]'],
      PM: ['font-text', 'font-medium', 'text-[1.25rem]', 'leading-none'],
      CB: ['font-text', 'font-bold', 'text-[0.875rem]', 'leading-[1rem]'],
    },
  },
})
