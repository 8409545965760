import Icon from '@ui/atoms/Icon'
import { IconNameProp, IconProps } from '@ui/atoms/Icon/types'
import ButtonOrLink from '../Button/ButtonOrLink'
import { ButtonOrLinkProps } from '../Button/types'
import { twMerge } from 'tailwind-merge'

interface Props extends ButtonOrLinkProps {
  name?: IconNameProp
  icon?: IconProps
  children?: React.ReactNode
}

export default function ButtonIcon({
  name,
  icon,
  children,
  className,
  ...props
}: Props) {
  const iconName = name || icon?.name

  return (
    <ButtonOrLink {...props} className="group relative inset-0">
      <div
        className={twMerge(
          'absolute left-1/2 top-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 scale-50 rounded-full bg-current opacity-0 transition-all duration-300 group-hover:scale-100 group-hover:opacity-10 group-active:scale-100 group-active:opacity-20'
        )}
      />
      <div
        className={twMerge(
          'relative inset-0 flex h-8 w-8 items-center justify-center rounded-full',
          className
        )}
      >
        {iconName && <Icon {...icon} name={iconName} />}
        {children}
      </div>
    </ButtonOrLink>
  )
}
