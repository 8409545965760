import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import useStepHistory from '@hooks/useStepHistory'
import Headline from '@ui/organisms/Headline'
import Section from '@ui/wrappers/Section'
import OptionColor from '@ui/options/OptionColor'
import useOptionClick from '@hooks/useOptionClick'
import { ColorConfig, ResolvedStepOption, StepConfig } from '@type/config'
import { AnimateHeadline } from '../styles'
import useColors from '@hooks/useColors'
import { useTranslatedStep } from '@hooks/useTranslated'

export default function TemplateColor() {
  const { t } = useTranslation()
  const { config, current, options, orderValues } = useStepHistory()

  const colors = useColors({
    config,
    values: orderValues,
  })
  const { title, desc } = useTranslatedStep(current?.step)

  const hasFashion = colors.some((c) => c.type === 'fashion')
  const option = options[0]
  if (!current?.step || !option) return null
  const { step } = current
  return (
    <>
      <Headline
        title={title}
        desc={!hasFashion ? desc : `${desc} ${t('fashion-warning')}`}
        className={AnimateHeadline}
      />
      <Section className={twMerge('grid grid-cols-2 gap-8 px-8 pb-8')}>
        {colors.map((color, index) => (
          <OptionWrapper
            key={color.id}
            index={index}
            color={color}
            option={options.find((o) => o.value === color.type) || option}
            step={step}
          />
        ))}
      </Section>
    </>
  )
}

const OptionWrapper = ({
  step,
  color,
  option,
}: {
  option: ResolvedStepOption
  step: StepConfig
  color: ColorConfig
  index: number
}) => {
  const { t } = useTranslation()
  const { selectOption } = useStepHistory()
  const title = t(`colors.${color.name}`)
  const onClick = useOptionClick({
    onClick: () =>
      selectOption({
        ...option,
        value: color.code,
        title,
      }),
    locked: option.locked,
    title: option.title,
    confirm: step.confirm,
  })

  return (
    <OptionColor
      title={title}
      desc={t(`color-type.${color.type}`)}
      onClick={onClick}
      color={{
        name: color.name,
        front: color.front,
        back: color.back,
      }}
      // FIXME: backdrop blur is bugged on chrome if element is animated
      // style={{
      //   animation: AnimateOptionEntry,
      //   animationDelay: AnimateOptionEntryDelay(index),
      // }}
    />
  )
}
