import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import Typography from '@ui/atoms/Typography'

import { PriceProps as Props } from './types'
import { priceGradient, priceIncluded, pricePrefixColor } from './styles'
import { hex } from '@utils/color'

export default function Price({
  value,
  sign,
  prefix,
  text,
  color = 'default',
  colorCustom,
}: Props) {
  const { t } = useTranslation()
  const isIncluded = !text && (!value || value === 0)
  return (
    <div
      className={twMerge(
        !colorCustom &&
          (isIncluded ? priceIncluded[color] : priceGradient[color]),
        'relative inset-0 flex h-10 w-max items-center justify-center rounded-full px-6'
      )}
      style={{
        backgroundColor: colorCustom
          ? isIncluded
            ? hex(colorCustom, 0.1)
            : colorCustom
          : undefined,
      }}
    >
      {prefix && (
        <Typography
          variant="XSB"
          component="span"
          className={twMerge(
            pricePrefixColor[color],
            'absolute -top-1 left-0 w-full -translate-y-full text-center uppercase'
          )}
        >
          <span style={{ color: colorCustom }}>{prefix}</span>
        </Typography>
      )}
      {!isIncluded && (
        <Typography
          variant="LGB"
          component="span"
          className="leading-none text-white"
        >
          {text ?? `${prefix ? '' : '+ '}${value}${sign || ''}`}
        </Typography>
      )}
      {isIncluded && (
        <Typography
          variant="MDB"
          className="uppercase"
          style={{ color: colorCustom || 'inherit' }}
        >
          <span style={{ color: colorCustom }}>{t('included')}</span>
        </Typography>
      )}
    </div>
  )
}
