import * as React from 'react'
import { Dialog } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import Typography from '@ui/atoms/Typography'
import { ScrollArea } from '@ui/generated/ui/scroll-area'

type ModalProps = {
  open: boolean
  onClose: () => void
  title?: React.ReactNode
  description?: React.ReactNode
  children?: React.ReactNode
  className?: string
}

export const Modal = ({
  open,
  onClose,
  title,
  description,
  children,
  className,
}: ModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className={twMerge(
        'fixed inset-0 z-[400] flex animate-fadein items-end justify-center overflow-y-auto p-8 shadow-lg',
        // 'backdrop-blur-lg',
        open && 'bg-black/50'
      )}
    >
      <Dialog.Overlay />
      <Dialog.Panel
        className={twMerge(
          'relative flex w-[calc(var(--max-width)_-_4rem)] flex-col gap-8 overflow-auto rounded-[2rem] bg-white  text-center',
          className
        )}
      >
        <ScrollArea className="w-full">
          <div className="flex flex-col gap-2 px-16 pt-12">
            {title && (
              <Dialog.Title
                as={Typography}
                variant="LGB"
                className="text-gray-900"
              >
                {title}
              </Dialog.Title>
            )}
            {description && (
              <Dialog.Description
                as={Typography}
                variant="MD"
                className="text-left text-text/50"
              >
                {description}
              </Dialog.Description>
            )}
          </div>
          {children}
        </ScrollArea>
      </Dialog.Panel>
    </Dialog>
  )
}

export default Modal
