import { get } from 'lodash'
import { StepHistory } from 'types/StepHistory'

export type PriceOptionValue = {
  value: string
  price: number
  label: string
}

export type OptionValue = {
  value: string
  label?: string
  price?: number
  summary?: 'automatic' | 'yes' | 'no'
}

export interface ParamValue extends OptionValue {
  param: string
}

export interface PriceParamValue extends PriceOptionValue {
  param: string
}

export type OptionValues = Record<string, OptionValue>

export const findOrderIndex = (history: StepHistory, order?: number) => {
  return history.findIndex(
    ({ step, value }) => step.variable === 'order' && Number(value) === order
  )
}

const findFirstOrderIndex = (history: StepHistory) => {
  return history.findIndex(
    ({ step, value }) => step.variable === 'order' && Number(value) >= 1
  )
}

const getHistorySlices = (history: StepHistory, order?: number) => {
  // No order set take whole history
  if (!order) {
    return {
      common: history,
      order: [],
    }
  }

  const orderStart = findOrderIndex(history, order)
  /**
   * Any history step including a numeric order value
   */
  const commonEnd = findFirstOrderIndex(history)
  // Still in common part of history
  if (orderStart < 0 && commonEnd < 0) {
    return {
      common: history,
      order: [],
    }
  }
  const commonHistory = history.slice(0, commonEnd)

  // Order did not start yet
  if (!orderStart) {
    return {
      common: commonHistory,
      order: [],
    }
  }

  const orderEnd = findOrderIndex(history, order + 1)

  // Order did not end yet take all common and history from order start
  if (orderEnd === -1) {
    return {
      common: commonHistory,
      order: history.slice(orderStart),
    }
  }

  return {
    common: commonHistory,
    order: history.slice(orderStart, orderEnd),
  }
}

/**
 * Returns history for given order number or whole history if no order is set
 */
export const getOrderHistory = (history: StepHistory, order?: number) => {
  const { common: commonHistory, order: orderHistory } = getHistorySlices(
    history,
    order
  )

  // Return common and order history
  return [...commonHistory, ...orderHistory]
}

export const getValuesFromHistory = (
  history: StepHistory,
  onlyOutput?: boolean,
  order?: number
) => {
  let params: OptionValues = {}

  const orderHistory = getOrderHistory(history, order)

  orderHistory.forEach(
    ({ step: { output, variable }, value, label, price, overrides }) => {
      const include = !onlyOutput ? true : output
      if (include && variable && value != null) {
        params[variable] = {
          value,
          price,
          label,
        }
      }
      if (overrides) {
        Object.entries(overrides).forEach(([key, override]) => {
          params[key] = {
            ...params[key],
            ...override,
          }
        })
      }
    }
  )

  return params
}

export const getValuesListFromHistory = (
  history: StepHistory,
  onlyOutput?: boolean
) => {
  const o1 = getHistorySlices(history, 1)
  const o2 = getHistorySlices(history, 2)
  const values1 = getValuesFromHistory([...o1.common, ...o1.order], onlyOutput)
  const values2 = getValuesFromHistory(o2.order, onlyOutput)

  const o1Vals = Object.entries(values1).map(([key, item]) => ({
    param: key,
    ...item,
  }))

  const o2Vals = Object.entries(values2).map(([key, item]) => ({
    param: key,
    ...item,
  }))

  return [...o1Vals, ...o2Vals]
}

export const getFlatOptionsValues = (values: OptionValues) => {
  return Object.entries(values).reduce((acc, [key, { value }]) => {
    return {
      ...acc,
      [key]: value,
    }
  }, {} as Record<string, string>)
}

const filterSummaryItemsWithPrice = (item: OptionValue) => {
  if (item.summary === 'yes') {
    return true
  }
  if (item.summary === 'no') {
    return false
  }
  // Default to automatic
  return item.price != null
}

const filterSummaryItems = (item: OptionValue) => {
  if (item.summary === 'yes') {
    return true
  }
  if (item.summary === 'no') {
    return false
  }
  // Default to
  return true
}

const filterOptionEntryWithPrice = (entry: [string, OptionValue]) =>
  filterSummaryItemsWithPrice(entry[1])

const filterOptionEntry = (entry: [string, OptionValue]) =>
  filterSummaryItems(entry[1])

export const getSummary = (values: OptionValues, withPrice = true) => {
  const entries = Object.entries(values)

  const filteredEntries = withPrice
    ? entries.filter(filterOptionEntryWithPrice)
    : entries.filter(filterOptionEntry)

  const selected = filteredEntries.map(([key, o]) => ({
    attribute: key,
    value: o.value,
    label: o.label,
    price: o.price,
  }))

  return {
    selected,
    total: selected.reduce((acc, { price }) => acc + (price ?? 0), 0),
  }
}

export const getSummaryFromParamValues = (
  values: ParamValue[],
  withPrice = true
) => {
  const filteredEntries = withPrice
    ? values.filter(filterSummaryItemsWithPrice)
    : values.filter(filterSummaryItems)

  const selected = filteredEntries.map((o) => ({
    attribute: o.param,
    value: o.value,
    label: o.label,
    price: o.price,
  }))

  return {
    selected,
    total: selected.reduce((acc, { price }) => acc + (price ?? 0), 0),
  }
}
