import { twMerge } from 'tailwind-merge'

import ButtonOrLink from '@ui/atoms/Button/ButtonOrLink'
import Typography from '@ui/atoms/Typography'
import Button from '@ui/atoms/Button'
import Img from '@ui/atoms/Img'

import { OptionLargeProps as Props } from './types'
import Price from '@ui/molecules/Price'

const height = 242
const sTransition = 'transition-all duration-300'

export default function OptionLarge({
  title,
  desc,
  image,
  button,
  price,
  disabled,
  ...props
}: Props) {
  return (
    <ButtonOrLink
      {...props}
      disabled={disabled}
      className={twMerge('group', disabled && 'grayscale')}
    >
      <div
        className={twMerge(
          sTransition,
          'relative inset-0',
          'overflow-hidden',
          'flex flex-row justify-between',
          'w-full rounded-[2rem]'
        )}
        style={{ height }}
      >
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[10] h-full w-full rounded-[inherit]',
            'shadow-border-black10'
            // 'group-active:shadow-border-black25'
          )}
        />
        <div
          className={twMerge(
            'absolute left-0 top-0 z-[1] h-full w-full rounded-[inherit]',
            'bg-gradient-to-r from-gray-75  to-white/0'
          )}
        />
        <div
          className={twMerge(
            sTransition,
            'absolute left-0 top-0 z-[1] h-full w-full rounded-[inherit]',
            'bg-gradient-to-r from-gray-200 to-white/0',
            'opacity-0 group-hover:opacity-50 group-active:opacity-100'
          )}
        />
        <div className="relative inset-0 z-[5] flex h-full w-1/2 flex-col justify-between p-8 pr-0">
          <div className="flex flex-col gap-4">
            <Typography variant="LGB" className="tracking-[-0.05em]">
              {title}
            </Typography>
            <Typography variant="SM" className="text-text/70">
              {desc}
            </Typography>
          </div>
          {!price && (
            <Button
              noninteractive
              disabled={disabled}
              variant="outlined"
              size="sm"
              className="group-hover:bg-initial group-active:bg-initial"
              {...button}
            />
          )}
          {price && <Price {...price} />}
        </div>
        {image && (
          <>
            <div className="absolute bottom-0 right-0 z-[0] w-1/2">
              <Img
                image={image}
                alt={title}
                className={twMerge(
                  sTransition,
                  'h-auto w-full object-contain object-bottom opacity-50 blur-[32px]',
                  !disabled && 'group-hover:scale-125 group-active:scale-125',
                  !disabled && 'group-hover:opacity-75 group-active:opacity-75'
                )}
              />
            </div>
            <div className="relative inset-0 z-[5] h-full w-1/2 rounded-r-[inherit]">
              <Img
                image={image}
                alt={title}
                className={twMerge(
                  sTransition,
                  'h-full w-full object-contain object-bottom',
                  !disabled && 'group-hover:scale-110 group-active:scale-110'
                )}
              />
            </div>
          </>
        )}
      </div>
    </ButtonOrLink>
  )
}
