import { ColorBuild } from '@type/config'
import { getGradientBreakpoint } from 'utils/color'
import {
  defaultColor,
  linearGradientProps,
  pathFrameProps,
  pathLensesProps,
} from './vectors'

interface Props extends Omit<React.SVGProps<SVGSVGElement>, 'color'> {
  color?: ColorBuild
  intensity?: number[]
  scale?: number
}

const gradientStart = 0.05
const gradientEnd = 0.8

export default function TintingPreview({
  scale = 1,
  color,
  className,
  ...props
}: Props) {
  const colors = color || defaultColor
  const o =
    props.intensity && props.intensity?.length > 0 ? props.intensity : [1, 1]

  const { name, front, back } = colors

  const id = o.reduce((acc, opacity) => acc + opacity, name)

  return (
    <svg
      width={200 * scale}
      height={64 * scale}
      viewBox="0 0 200 64"
      fill="none"
      className={className}
    >
      <path {...pathLensesProps} fill={`url(#${id}-tinting-back)`} />
      <path {...pathLensesProps} fill={`url(#${id}-tinting-front)`} />
      <path {...pathFrameProps} fill="#010101" />
      <defs>
        <linearGradient id={`${id}-tinting-back`} {...linearGradientProps}>
          {back?.map(({ color, opacity }, index) => (
            <stop
              key={index}
              offset={getGradientBreakpoint(
                index,
                back.length,
                gradientStart,
                gradientEnd
              )}
              stopColor={color}
              stopOpacity={(opacity / 100) * o[index % o.length]!}
            />
          ))}
        </linearGradient>
        <linearGradient id={`${id}-tinting-front`} {...linearGradientProps}>
          {front?.map(({ color, opacity }, index) => (
            <stop
              key={index}
              offset={getGradientBreakpoint(
                index,
                front.length,
                gradientStart,
                gradientEnd
              )}
              stopColor={color}
              stopOpacity={(opacity / 100) * o[index % o.length]!}
            />
          ))}
        </linearGradient>
      </defs>
    </svg>
  )
}
