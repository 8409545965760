import AppConfig from 'AppConfig'
import { useAtom, useAtomValue } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { StepHistoryItem } from '../types/StepHistory'
import { ResolvedStepOption } from 'types/config'
import useConfig from 'api/useConfig'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  getFlatOptionsValues,
  getSummary,
  getValuesFromHistory,
} from 'utils/optionUtils'
import useParams from './useParams'
import { applyOptionAction } from 'utils/paramActions'
import { InitialParams } from '@type/initialParams'
import { historyAtom } from '@jotai/history'
import postResult from '@api/postResult'
import useHistoryValues from './useHistoryValues'
import useFilteredOptions from './useFilteredOptions'

export const useStepHistoryHydrate = (initialStepId: string) => {
  const { data } = useConfig()
  const initialParams = useParams()

  const steps = data?.steps

  if (!steps) {
    console.error('Config', data)
    throw new Error('No steps found in config')
  }

  const initialValue = useMemo(() => {
    const step = steps[initialParams?.step || initialStepId]
    if (!step) {
      throw new Error(`Step ${initialStepId} not found`)
    }

    return [
      {
        step,
        value: null,
      },
    ]
  }, [initialStepId])

  useHydrateAtoms([[historyAtom, initialValue]])
}

const updateHistoryStep = ({
  current,
  option,
  done,
}: {
  current: StepHistoryItem
  option: ResolvedStepOption
  done?: boolean
  params?: InitialParams
}) => ({
  step: current.step,
  value: option.value,
  overrides: option.overrides,
  price: option.price,
  label: option.title,
  action: current.action,
  done,
})

/**
 * This needs to be pure as its used in multiple places
 */
const useStepHistory = () => {
  const [history, setHistory] = useAtom(historyAtom)
  const { data } = useConfig()
  const navigate = useNavigate()
  const params = useParams()
  const [isPosting, setIsPosting] = useState(false)
  const steps = data?.steps

  const dev = AppConfig.isDev(params)

  if (!steps) {
    throw new Error('Steps history is not initialized')
  }

  const selectOption = useCallback(
    (option: ResolvedStepOption, replace?: boolean) => {
      console.log('selectOption', option, replace)
      if (isPosting) {
        return
      }
      setHistory((prev) => {
        const current = prev[prev.length - 1]
        const rest = prev.slice(0, prev.length - 1)
        if (!current) {
          return prev
        }

        const selectedStepId = option.nextStep

        if (!selectedStepId) {
          const newHistory = [
            ...rest,
            updateHistoryStep({
              current,
              option,
              done: true,
            }),
          ]

          if (!params) {
            throw new Error('Params not set')
          }
          setIsPosting(true)
          postResult({
            params: applyOptionAction(params, option.action),
            history: newHistory,
            variablesConfig: data.variables,
            onSuccess: () => {
              setIsPosting(false)
              if (dev) {
                navigate(`/result${window.location.search}`)
              }
            },
            onError: () => {
              setIsPosting(false)
              if (dev) {
                navigate(`/result${window.location.search}`)
              }
            },
          })

          return newHistory
        }

        const selectedStepObj = steps[selectedStepId]

        if (!selectedStepObj) {
          throw new Error(`Step ${selectedStepId} not found`)
        }

        const nextHistoryItem = {
          step: selectedStepObj,
          value: null,
          action: option.action,
        }

        if (replace) {
          return [...rest, nextHistoryItem]
        }

        return [
          ...rest,
          updateHistoryStep({
            current,
            option,
          }),
          nextHistoryItem,
        ]
      })
    },
    []
  )

  const current = history[history.length - 1]

  const back = useCallback(() => {
    setHistory((prev) => {
      // For Automatic steps we need to go back multiple steps
      const reverse = prev.slice(0, -1).reverse()
      const lastIndex = reverse.findIndex(
        (item) => item.step?.template !== 'Automatic'
      )
      if (lastIndex < 0) {
        return []
      }

      const backBy = lastIndex + 1
      if (prev.length <= backBy) {
        return []
      }

      return prev.slice(0, prev.length - backBy)
    })
  }, [])

  const hasBack = useMemo(() => {
    return (
      history.filter((item) => item.step?.template !== 'Automatic')?.length > 1
    )
  }, [history])

  const { values, orderValues, order } = useHistoryValues(history)
  const filteredOptions = useFilteredOptions({
    step: current?.step,
    // For Automatic steps we need to use all values
    values: current?.step?.template === 'Automatic' ? values : orderValues,
    order,
  })

  const frameNumber = values?.order?.value ? parseInt(values?.order?.value) : 1
  const frameId =
    (params?.orders && frameNumber && params.orders[frameNumber - 1]?.frame) ||
    null

  // const frame =
  //   values?.type?.value === 'DP'
  //     ? {
  //         id: frameId,
  //         number: frameNumber,
  //       }
  //     : null

  const frame = {
    id: frameId,
    number: frameNumber,
  }

  return {
    params,
    dev,
    hasBack,
    history: history,
    current,
    selectOption,
    values,
    orderValues,
    back,
    config: data,
    isPosting,
    ...filteredOptions,
    frame,
  }
}

export const useResult = () => {
  const value = useAtomValue(historyAtom)

  const values = useMemo(
    () => getFlatOptionsValues(getValuesFromHistory(value)),
    [value]
  )

  const outputValues = useMemo(() => getValuesFromHistory(value, true), [value])

  const summary = useMemo(() => getSummary(outputValues, false), [outputValues])

  return {
    values,
    ...summary,
  }
}

export default useStepHistory
