import { ColorBuild } from '@type/config'

export const pathFrameProps = {
  d: 'M199.53 5.86717C187.612 -0.110605 163.551 0.000141474 155.739 0.000141474C155.685 0.000141474 155.639 0.00997326 155.585 0.0165279C155.111 0.00997326 154.635 0.000141474 154.149 0.000141474C148.169 0.000141474 143.003 0.38292 138.523 1.04887C138.479 1.05477 138.436 1.05477 138.391 1.06526C138.281 1.08165 138.171 1.10524 138.071 1.12163C134.793 1.63093 131.903 2.30931 129.355 3.11489C120.491 5.60366 112.04 8.88745 100.003 8.88745C87.9578 8.88745 79.51 5.60551 70.6484 3.11489C68.104 2.30933 65.2082 1.63093 61.9367 1.12163C61.8267 1.10524 61.7225 1.08165 61.6106 1.06526C61.5663 1.05543 61.5227 1.05543 61.4784 1.04887C57.0044 0.37834 51.8404 0.000141474 45.8548 0.000141474C45.3567 0.000141474 44.8945 0.00997326 44.4147 0.0165279C44.3587 0.00997326 44.3209 0.000141474 44.2649 0.000141474C36.4511 0.000141474 12.3984 -0.110632 0.473445 5.86717C-0.592301 6.40071 0.44545 18.9671 0.649227 19.4666C0.985159 20.3063 4.25721 26.1936 8.0092 40.4664C10.5255 53.7441 18.7468 64 43.0207 64C59.3325 64 71.8423 58.9543 79.5289 49.8448C79.5569 49.8107 79.583 49.7884 79.611 49.7563C91.7568 35.877 86.4586 21.6889 99.9942 21.6889C113.536 21.6889 108.238 35.8763 120.377 49.7563C120.411 49.7884 120.443 49.8107 120.465 49.8448C128.147 58.9543 140.662 64 156.974 64C181.253 64 189.463 53.7427 191.98 40.4664H191.992C195.732 26.1945 199.022 20.3039 199.354 19.4666C199.551 18.9659 200.591 6.40144 199.531 5.86717H199.53ZM43.0276 59.7334C14.4227 59.7334 11.2444 45.0669 11.2444 27.7328C11.2444 13.4229 15.3941 4.26638 45.8543 4.26638C73.6642 4.26638 83.9941 12.1777 83.9941 23.4657C83.9948 41.8664 73.4832 59.7334 43.0293 59.7334H43.0276ZM156.974 59.7334C126.514 59.7334 116.009 41.8664 116.009 23.4674C116.009 12.1797 126.339 4.26806 154.149 4.26806C184.609 4.26806 188.759 13.4234 188.759 27.7345C188.759 45.0646 185.581 59.7334 156.976 59.7334H156.974Z',
} satisfies React.SVGProps<SVGLinearGradientElement>

export const pathLensesProps = {
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  d: 'M83.312 11.2638C75.836 2.11244 19.3748 -2.20171 10.6246 8.62157C4.24988 20.954 5.12486 41.0899 18.4998 53.5489C31.8747 66.0078 61.4365 62.1076 71.3119 54.8077C81.1872 47.5079 94.3122 24.7289 83.312 11.2638ZM116.547 11.297C124.023 2.14564 180.485 -2.16851 189.235 8.65477C195.609 20.9872 194.735 41.1231 181.36 53.5821C167.985 66.041 138.423 62.1408 128.548 54.841C118.672 47.5411 105.547 24.7621 116.547 11.297Z',
} satisfies React.SVGProps<SVGLinearGradientElement>

export const linearGradientProps = {
  x1: '152.724',
  y1: '2.26465',
  x2: '152.724',
  y2: '61.6537',
  gradientUnits: 'userSpaceOnUse',
} satisfies React.SVGProps<SVGLinearGradientElement>

export const defaultColor: ColorBuild = {
  name: 'tinting-default',
  front: [
    {
      color: '#000000',
      opacity: 0,
    },
  ],
  back: [
    {
      color: '#FFFFFF',
      opacity: 0,
    },
  ],
}
