import { atom, useAtom, useSetAtom } from 'jotai'

type Modal = null | 'summary' | 'frame'

interface Atom {
  modal: Modal
}

export const modalsAtom = atom<Atom>({
  modal: null,
})

export const useModals = () => {
  const [{ modal }] = useAtom(modalsAtom)
  const setModalsAtom = useSetAtom(modalsAtom)

  const setModal = (modal: Modal) => {
    setModalsAtom({
      modal,
    })
  }

  const toggleModal = (modal: Modal) => {
    setModalsAtom((prev) => {
      return {
        modal: prev.modal === modal ? null : modal,
      }
    })
  }

  return { modal, setModal, toggleModal }
}

export const useModalsState = () => {
  const [{ modal }, setModalsAtom] = useAtom(modalsAtom)

  return { modal, setModal: setModalsAtom }
}
